/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react';
import { useNetworkState } from 'react-use';
import { twMerge } from 'tailwind-merge';
import { getImage } from '@utils/imageTransforms';
import { Image } from '@supertakst/model-common';
import {
  Button,
  CameraIcon,
  CheckIcon,
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
  XIcon,
} from '@supertakst/ui-common';

type Props = {
  images: Image[];
  onThumbClick: (image: Image) => void;
  onUploadClick: () => void;
  onSelectedAction: (action: string, images: Image[]) => void;
};

const Browser = ({ images, onThumbClick, onUploadClick, onSelectedAction }: Props) => {
  const [selectedImages, setSelectedImages] = useState<Image[] | []>([]);
  const [filter, setFilter] = useState('all');
  const [filteredImages, setFilteredImages] = useState<Image[] | []>(images);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { online } = useNetworkState();

  const handleSetSelected = (image: Image) => {
    const alreadySelected = selectedImages.find((i) => i.id === image.id);
    const newSelected = alreadySelected
      ? selectedImages.filter((i) => i.id !== image.id)
      : [...selectedImages, image];
    if (newSelected.length > 0 && !menuOpen) {
      setMenuOpen(true);
    }
    setSelectedImages(newSelected);
  };

  const doSort = (value) => {
    const filtered = images.filter((image) => {
      if (value === 'report') {
        return image.includeInReport === true;
      }
      if (value === 'archive') {
        return image.includeInReport !== true;
      }
      return image;
    });
    setFilter(value);
    setFilteredImages(filtered);
    setSelectedImages([]);
  };

  const handleOnMenuClick = () => {
    if (menuOpen === true) {
      setSelectedImages([]);
    }
    setMenuOpen(!menuOpen);
  };

  const handleAction = (action: string, images: Image[]) => {
    const imageIds = images.map((image) => image.id);
    const newImages = filteredImages.filter((image) => !imageIds.includes(image.id));
    setFilteredImages(newImages);
    setSelectedImages([]);
    setMenuOpen(false);
    onSelectedAction(action, images);
  };

  const Buttons = [
    { id: 'all', text: 'Alle bilder' },
    { id: 'report', text: 'Rapportbilder' },
    { id: 'archive', text: 'Arkiv' },
  ];

  const disabled = selectedImages.length === 0;

  return (
    <>
      {menuOpen && (
        <div className="absolute top-0 right-0 left-0 py-10 px-4 flex gap-4 overflow-x-auto scrollbar-hide whitespace-nowrap z-5 bg-[#F7F8FC]">
          <div className="flex space-x-6 [&>*]:flex [&>*]:gap-1 [&>:first-child]:ml-auto [&>:last-child]:pr-36 justify-start bg-supertakst-gray-50 w-full">
            <button
              onClick={onUploadClick}
              disabled={!online}
              className={!online ? 'text-supertakst-blue-900/25' : ''}
            >
              <CameraIcon /> Last opp
            </button>
            <button
              disabled={disabled}
              className={disabled ? 'text-supertakst-blue-900/25' : ''}
              onClick={() => handleAction('edit', selectedImages)}
            >
              <PencilIcon strokeOpacity={disabled ? '0.25' : '1'} /> Gi beskrivelse
            </button>
            <button
              disabled={disabled || !online}
              className={disabled || !online ? 'text-supertakst-blue-900/25' : ''}
              onClick={() => handleAction('delete', selectedImages)}
            >
              <TrashIcon strokeOpacity={disabled ? '0.25' : '1'} /> Slett
            </button>
          </div>
        </div>
      )}
      <div className="absolute top-0 right-0 py-10 px-4 flex">
        <div className="bg-gradient-to-r from-transparent to-[#F7F8FC] relative w-10" />
        <button className="flex gap-2 bg-[#F7F8FC]" onClick={handleOnMenuClick}>
          {!menuOpen ? (
            <>
              Valg <DotsVerticalIcon className="scale-75" />
            </>
          ) : (
            <>
              Valg <XIcon className="scale-75" />
            </>
          )}
        </button>
      </div>
      <div className="w-full px-4 md:max-w-[70%] md:px-0 flex flex-col mx-auto">
        <div className="flex justify-center gap-2 p-2 md:p-5">
          {Buttons.map((button) => (
            <Button
              key={button.id}
              buttonType="secondary"
              className={twMerge(
                'rounded-2xl md:rounded-full py-2 px-4 md:py-4 md:px-8',
                filter === button.id && 'bg-supertakst-green-300 text-white'
              )}
              onClick={() => doSort(button.id)}
            >
              {button.text}
            </Button>
          ))}
        </div>
        <div className="grid grid-cols-2 gap-4 m-auto justify-center my-6 w-full">
          {filteredImages.map((image) => {
            const isSelected = selectedImages.find((i) => i.id === image.id);
            return (
              <div
                key={image.id}
                className={twMerge(
                  'relative bg-white aspect-[4/3] border border-solid rounded-lg border-light-gray p-2 md:p-4 cursor-pointer',
                  isSelected && 'border-4 border-blue-700'
                )}
              >
                <div
                  onClick={() =>
                    selectedImages.length === 0 && !menuOpen
                      ? onThumbClick(image)
                      : handleSetSelected(image)
                  }
                  className={twMerge(
                    'w-full h-full bg-contain bg-no-repeat bg-center',
                    selectedImages.length > 0 && !isSelected && 'opacity-60'
                  )}
                  style={{ backgroundImage: `url(${getImage(image.id)})` }}
                ></div>
                <div
                  onClick={() => handleSetSelected(image)}
                  className={twMerge(
                    'absolute top-2 left-2 xl:top-7 xl:left-7 bg-supertakst-blue-900 w-7 h-7 rounded-full opacity-20 border-white border-2 flex justify-center items-center',
                    isSelected && 'bg-supertakst-blue-300 opacity-100 border-0'
                  )}
                >
                  {isSelected && <CheckIcon className="[&>path]:stroke-white" />}
                </div>
                <p className="h-6 overflow-hidden text-ellipsis whitespace-nowrap">
                  {image.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Browser;
