import React, { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Header from '@components/common/Header';
import { useFormContext } from 'react-hook-form';
import { PencilAltIcon } from '@supertakst/ui-common';
import { useRouter } from 'next/router';
import { useUpdateAtom } from 'jotai/utils';
import { partNameAtomFamily } from '@store/atoms';

type Props = {
  className?: string;
  partName: string;
  id?: string;
};

const NameInput = ({ className, partName, id = 'name' }: Props) => {
  const valueName = `${id}.value`;
  const { query } = useRouter();
  const version = parseInt(query.version as string, 10);
  const tilstandsrapportId = parseInt(query.id as string, 10);
  const partId = parseInt(query.partId as string);
  const setName = useUpdateAtom(
    partNameAtomFamily({
      tilstandsrapportId,
      version,
      partId,
    })
  );

  const inputRef = useRef<HTMLInputElement | null>(null);
  const { register, setFocus, watch } = useFormContext();
  const { onChange, onBlur, name, ref } = register(valueName);
  const givenName = watch(valueName);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  return (
    <div
      data-test-id="name"
      className={twMerge(
        'pb-2 flex border-b-2 border-b-solid border-b-supertakst-blue-900/10 items-center relative mb-10 mt-12',
        className && className
      )}
    >
      <Header as="h2" className="whitespace-nowrap">
        {partName}
        {(hasFocus || (givenName && givenName.length > 0)) && <span className="mr-3">:</span>}
      </Header>

      <input
        data-test-type="input"
        onKeyPress={(e) => {
          if (e.code === 'Enter') {
            inputRef.current && inputRef.current.blur();
            e.preventDefault();
          }
        }}
        onFocus={() => setHasFocus(true)}
        type="text"
        ref={(e) => {
          ref(e);
          inputRef.current = e;
        }}
        onChange={onChange}
        onBlur={(e) => {
          onBlur(e);
          setHasFocus(false);
          setName(givenName);
        }}
        autoComplete="off"
        name={name}
        className="truncate text-lg md:text-3xl w-full font-bold p-0 m-0 bg-transparent border-none shrink-1 border-transparent focus:border-transparent focus:ring-0"
      />
      {!hasFocus && (
        <button
          onClick={() => {
            setFocus('name.value');
          }}
          type="button"
          className="flex text-xs font-bold items-center whitespace-nowrap"
        >
          <PencilAltIcon className="scale-75" /> Gi navn
        </button>
      )}
    </div>
  );
};

export default NameInput;
