import { Button } from '@supertakst/ui-common';
import React from 'react';

const NewVersion = () => {
  return (
    <div className="p-4 text-center flex flex-col bg-supertakst-gray-900 items-center">
      <p className="text-base">En ny version av Supertakst Befaring er tilgjengelig.</p>
      <p className="text-base">
        Husk å lagre alle endringer før du trykker på &ldquo;Last inn på nytt&rdquo;
      </p>
      <div className="mt-4">
        <Button buttonType="tertiary" type="button" onClick={() => window.location.reload()}>
          Last inn på nytt
        </Button>
      </div>
    </div>
  );
};

export default NewVersion;
