import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Textarea from '@components/common/atoms/Textarea';
import TGSelector from '@components/common/TGSelector';
import BoolInput from '@components/common/CheckBoolean/BoolInput';
import CheckHeader from '@components/common/CheckHeader';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from '@components/common/CheckCard';
import Phrases from '@components/common/Phrases/v1';
import Tooltip from '@components/common/Tooltip';

type Props = {
  id: string;
  header: string;
  hasTg?: boolean;
  tgReadonly?: boolean;
  tilstandsgrader?: Array<-1 | 0 | 1 | 2 | 3>;
  hideComment?: boolean;
};

const CheckBoolean = ({
  id,
  header,
  hasTg = false,
  hideComment = false,
  tgReadonly = false,
  tilstandsgrader = [-1, 0, 1, 2, 3],
}: Props) => {
  const boolName = `${id}.value`;
  const commentName = `${id}.comment`;
  const tgName = `${id}.tg`;
  const { control, getValues } = useFormContext();

  hideComment = hideComment === true || typeof getValues(boolName) !== 'boolean';

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <Controller
          name={boolName}
          control={control}
          render={({ field: { onChange, onBlur, ref, name, value } }) => {
            return (
              <BoolInput
                onChange={onChange}
                onBlur={onBlur}
                id={name}
                inputRef={ref}
                value={value}
              />
            );
          }}
        />
        {!hideComment && (
          <>
            <Textarea className="mt-6" header="Vurdering og beskrivelse" id={commentName} />
            <Phrases
              id={id}
              textId={commentName}
              tgId={tgName}
              hasTg={hasTg}
              checkHeader={header}
            />
          </>
        )}
        {hasTg && (
          <TGSelector
            className="mt-6"
            header="Tilstandsgrad"
            id={tgName}
            tilstandsgrader={tilstandsgrader}
            readonly={tgReadonly}
          />
        )}
      </CheckCardContent>
    </CheckCardItem>
  );
};

export default CheckBoolean;
