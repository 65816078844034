import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import LogRocket from 'logrocket';
import { initStore } from '@utils/localDb';
import * as Sentry from '@sentry/nextjs';

type Props = {
  children: React.ReactNode;
};

const SetIdToken = ({ children }: Props) => {
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const { user, getAccessTokenSilently, isLoading } = useAuth0();
  useEffect(() => {
    if (!isLoading) {
      (async () => {
        try {
          await getAccessTokenSilently();
          await initStore(user?.sub);
          const logrocketIgnoredUsers = process.env.NEXT_PUBLIC_LOGROCKET_IGNORED_USERS
            ? JSON.parse(process.env.NEXT_PUBLIC_LOGROCKET_IGNORED_USERS)
            : [];
          if (
            user &&
            process.env.NODE_ENV !== 'development' &&
            !logrocketIgnoredUsers.includes(user.email)
          ) {
            Sentry.setUser({ id: user.sub, email: user.email });
            LogRocket.identify(`${user.sub || 'UNKNOWN'}`, {
              name: `${user.name || 'UNKNOWN'}`,
              email: `${user.email || 'UNKNOWN'}`,
            });
          }
        } catch (err) {
          console.log('err', err);
        } finally {
          setIsFetched(true);
        }
      })();
    }
  }, [user, getAccessTokenSilently, isLoading]);

  return <>{isFetched && children}</>;
};

export default SetIdToken;
