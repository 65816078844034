import React from 'react';
import { useFormContext } from 'react-hook-form';
import ControlledButton from '@components/common/atoms/ControlledButton';
import { tilstandsgrader as tgs } from '@utils/tilstandsgrader';

type Props = {
  onChange: (value: boolean | null) => void;
  onBlur: () => void;
  inputRef: any;
  value: number | null;
  id: string;
  readonly?: boolean;
  tilstandsgrader: Array<-1 | 0 | 1 | 2 | 3>;
  excludeIU?: boolean;
};

const TGInput = ({ onChange, onBlur, inputRef, value, readonly, tilstandsgrader }: Props) => {
  const { trigger } = useFormContext();
  const doOnClick = (ev: React.MouseEvent<HTMLButtonElement>, newValue) => {
    ev.preventDefault();
    if (readonly) return;
    if (value === newValue) {
      onChange(null);
    } else {
      onChange(newValue);
    }
    trigger();
  };

  return (
    <div className="flex flex-wrap -ml-3 -mb-3 child:ml-3 child:mb-3" ref={inputRef}>
      {tilstandsgrader?.map((tg) => (
        <ControlledButton
          key={tg}
          onClick={(ev) => doOnClick(ev, tg)}
          onBlur={onBlur}
          label={tgs[`${tg}`].label}
          selected={value === tg}
        />
      ))}
    </div>
  );
};

export default TGInput;
