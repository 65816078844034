/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react';
import { useNetworkState } from 'react-use';
import { useDropzone } from 'react-dropzone';
import { Button, PhotographIcon } from '@supertakst/ui-common';
import { Image } from '@supertakst/model-common';
import { deleteImages, generateSignature, uploadImage } from '@api/image';
import DeleteButton from '@components/common/atoms/DeleteButton';
import { getImage } from '@utils/imageTransforms';

type Props = {
  folder: string;
  maxFiles?: number;
  onUploadStart?: () => void;
  onUploadDone: (images?: Image[]) => void;
  onDelete: (image: Image) => void;
  existingImages?: Image[];
  type?: string;
};

const Spinner = () => {
  return (
    <svg
      className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const SimpleImages = ({
  folder,
  maxFiles = 1,
  onUploadStart,
  onUploadDone,
  onDelete,
  existingImages,
  type,
}: Props) => {
  const [images, setImages] = useState<Image[] | undefined>(existingImages);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<{ filename: string; error: string }> | undefined>();
  const { online } = useNetworkState();

  const handleUpload = async (imageUploads: File[]) => {
    const params = {
      folder: folder,
    };

    setIsUploading(true);
    onUploadStart && onUploadStart();
    const signData = await generateSignature(params);

    const uploadResponses = await Promise.allSettled(
      imageUploads.map((image) => uploadImage(image, signData, params))
    );

    let fulfilled: Image[] = [];
    let rejected: string[] = [];

    uploadResponses.forEach((item) => {
      if (item.status === 'fulfilled') {
        fulfilled.push(item.value);
      } else {
        rejected.push(item.reason);
      }
    });
    setImages((images) => (images ? [...images, ...fulfilled] : fulfilled));
    onUploadDone(fulfilled);
    setIsUploading(false);
  };

  const handleDeleteImage = async (image: Image) => {
    await deleteImages([image]);
    setImages((images) => images?.filter((i) => i.id !== image.id));
    onDelete(image);
  };

  const errorMessages = {
    'file-too-large': 'for stor, maks 10MB',
    'file-invalid-type': 'ukjent filtype, kun png og jpg er tillatt',
  };

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    setErrors(undefined);
    if (acceptedFiles.length > 0) {
      await handleUpload(acceptedFiles);
    }
    if (rejectedFiles.length > 0) {
      const fileErrors = rejectedFiles.map((file) => {
        return {
          filename: file.file.name,
          error: errorMessages[file.errors[0].code] ?? 'ukjent feil',
        };
      });
      setErrors(fileErrors);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxFiles,
    maxSize: 10485760, // 10MB
    useFsAccessApi: false,
    noClick: true,
    noKeyboard: true,
    multiple: maxFiles === 1 ? false : true,
    onDrop,
  });

  const renderThumbs = () => {
    return images && images.length ? (
      <ul>
        {images.map((image) => (
          <li key={image.id}>
            <img src={getImage(image.id, type)} className="w-full" />
            {online && (
              <DeleteButton type="button" className="mt-2" onClick={() => handleDeleteImage(image)}>
                Slett
              </DeleteButton>
            )}
          </li>
        ))}
      </ul>
    ) : null;
  };

  const renderErrors = () => {
    return errors ? (
      <ul>
        {errors.map((error) => {
          return (
            <li key={error.filename}>
              {error.filename} - <span className="text-supertakst-red-900">({error.error})</span>
            </li>
          );
        })}
      </ul>
    ) : null;
  };

  const renderInput = () => {
    return (
      (!images || images.length < maxFiles) &&
      (!online ? (
        <p className="text-[#FB4242]">Du må være online for å laste opp bilder</p>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button
            type="button"
            buttonType="secondary"
            className="py-2 px-4 sm:py-4 sm:px-8"
            icon={isUploading ? Spinner : PhotographIcon}
            disabled={isUploading}
            onClick={open}
          >
            Last opp
          </Button>
        </div>
      ))
    );
  };

  return (
    <div className="space-y-4">
      {renderThumbs()}
      {renderErrors()}
      {renderInput()}
    </div>
  );
};

export default SimpleImages;
