import React from 'react';
import Header from '@components/common/Header';

type Props = {
  children: React.ReactNode;
};

const CheckHeader = ({ children }: Props) => {
  return (
    <Header as="h4" className="font-bold">
      {children}
    </Header>
  );
};

export default CheckHeader;
