import { createContext, ReactNode, useContext } from 'react';

type DefaultValues = any | undefined;

type Props = {
  children: ReactNode;
  defaultValues: DefaultValues;
};

type ContextProps = {
  defaultValues: DefaultValues;
};

const DefaultValuesContext = createContext<ContextProps | undefined>(undefined);

const DefaultValuesContextProvider = ({ children, defaultValues }: Props) => {
  return (
    <DefaultValuesContext.Provider value={{ defaultValues }}>
      {children}
    </DefaultValuesContext.Provider>
  );
};

export function useDefaultValuesContext() {
  const context = useContext(DefaultValuesContext);
  if (context === undefined) {
    throw new Error('useDefaultValuesContext must be used within a DefaultValuesContextProvider');
  }
  return context;
}

export default DefaultValuesContextProvider;
