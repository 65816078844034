import { PartAvailability } from '@supertakst/model-common';
import React from 'react';
import TextInput from '@components/common/CheckText/TextInput';
import { Controller, useFormContext } from 'react-hook-form';
import { availabilityHasChangedAtom, partAvailabilityAtomFamily } from '@store/atoms';
import { useRouter } from 'next/router';
import { useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';

type Props = {
  header: string;
  id?: string;
};

const alternatives = [
  { value: PartAvailability.Available.id, label: PartAvailability.Available.name },
  { value: PartAvailability.NotAvailable.id, label: PartAvailability.NotAvailable.name },
  { value: PartAvailability.NotRelevant.id, label: PartAvailability.NotRelevant.name },
];

const AvailabilityInput = ({ header, id = 'availability' }: Props) => {
  const valueName = `${id}.value`;
  const { control } = useFormContext();
  const { query } = useRouter();
  const version = parseInt(query.version as string, 10);
  const tilstandsrapportId = parseInt(query.id as string, 10);
  const partId = parseInt(query.partId as string);
  const setAvailabilityHasChanged = useUpdateAtom(availabilityHasChangedAtom);
  const [availability, setAvailability] = useAtom(
    partAvailabilityAtomFamily({
      tilstandsrapportId,
      version,
      partId,
    })
  );

  return (
    <div
      className="bg-white border border-solid rounded text-supertakst-blue-900"
      data-test-id="availability"
    >
      <div className="py-5 px-5">
        <h4 className="text-base font-bold">{header}</h4>
      </div>
      <div className="pt-8 pb-10 px-5 overflow-hidden border-t border-t-[#020C56]/6">
        <Controller
          name={valueName}
          control={control}
          render={({ field: { onChange, onBlur, ref, name, value } }) => {
            return (
              <TextInput
                onChange={(e) => {
                  const newValue = e as string;
                  if (availability !== newValue) {
                    setAvailabilityHasChanged(true);
                  }
                  onChange(newValue);
                  setAvailability(newValue);
                }}
                onBlur={onBlur}
                id={name}
                inputRef={ref}
                value={value}
                disableUncheck={true}
                alternatives={alternatives}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default AvailabilityInput;
