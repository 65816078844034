import { Phrase } from '@supertakst/model-common';
import { useQuery } from '@tanstack/react-query';
import { fetchAllSystemPhrases, fetchAllUserPhrases } from '@api/phrases';

export const phrasesQueryKeys = {
  userPhrases: () => ['userPhrases'],
  systemPhrases: () => ['systemPhrases'],
};

export function useUserPhrases(options = {}) {
  return useQuery<Phrase[]>(
    phrasesQueryKeys.userPhrases(),
    async () => {
      return await fetchAllUserPhrases();
    },
    options
  );
}

export function useSystemPhrases(options = {}) {
  return useQuery<Phrase[]>(
    phrasesQueryKeys.systemPhrases(),
    async () => {
      return await fetchAllSystemPhrases();
    },
    options
  );
}
