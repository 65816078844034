import React, { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  children: React.ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'label';
  className?: string;
} & HTMLAttributes<HTMLHeadingElement | HTMLLabelElement>;

const classes = {
  h1: 'text-3xl md:text-4xl font-bold',
  h2: 'text-lg md:text-3xl font-bold',
  h3: 'text-base md:text-lg font-medium',
  h4: 'text-base font-medium',
  label: 'text-base font-normal',
};

const Header = ({ children, as = 'h1', className, ...rest }: Props) => {
  const WrapperElement = as;
  const baseClass = classes[as];
  return (
    <WrapperElement className={twMerge(baseClass, className)} {...rest}>
      {children}
    </WrapperElement>
  );
};

export default Header;
