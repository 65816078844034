import React from 'react';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  noPadding?: boolean;
};

const CheckCardContent = ({ children, noPadding = false }: Props) => {
  return (
    <div className={classNames(!noPadding && 'pt-4 pb-6 px-4', 'border-t border-t-[#020C56]/6')}>
      {children}
    </div>
  );
};

export default CheckCardContent;
