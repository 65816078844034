import { useEffect, useRef } from 'react';
import { Conflict, conflictsAtom } from '@store/atoms';
import { useAtom } from 'jotai';

type Message = {
  action: string;
  data?: any;
};

const Workers = () => {
  const onGoingTilstandsrapporterWorker = useRef<Worker>();
  const syncLocalTilstandsrapporterWorker = useRef<Worker>();
  const [conflicts, setConflicts] = useAtom(conflictsAtom);

  useEffect(() => {
    // OnGoing
    onGoingTilstandsrapporterWorker.current = new Worker(
      new URL('../../workers/fetchOngoingTilstandsrapporter.ts', import.meta.url)
    );
    onGoingTilstandsrapporterWorker.current.onmessage = (event: MessageEvent<Conflict[]>) => {
      console.warn('SYNC CONFLICTS (fetchOngoingTilstandsrapporter)', event.data);
      setConflicts([...conflicts, ...event.data]);
    };

    // LocalDirty
    syncLocalTilstandsrapporterWorker.current = new Worker(
      new URL('../../workers/syncLocalTilstandsrapporter.ts', import.meta.url)
    );
    syncLocalTilstandsrapporterWorker.current.onmessage = (event: MessageEvent<Conflict[]>) => {
      console.warn('SYNC CONFLICTS (syncLocalTilstandsrapporter)', event.data);
      setConflicts([...conflicts, ...event.data]);
    };

    const message: Message = { action: 'init' };

    onGoingTilstandsrapporterWorker.current?.postMessage(message);
    syncLocalTilstandsrapporterWorker.current?.postMessage(message);
    return () => {
      onGoingTilstandsrapporterWorker.current?.terminate();
      syncLocalTilstandsrapporterWorker.current?.terminate();
    };
  }, [conflicts]);

  return null;
};

export default Workers;
