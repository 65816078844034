import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const DeleteButton = ({ children, className, ...rest }: Props) => {
  return (
    <button
      className={twMerge(
        'py-2 px-6 text-base rounded bg-red-500 hover:bg-red-600 text-white',
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export default DeleteButton;
