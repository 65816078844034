import { del } from 'idb-keyval';
import React from 'react';
import { Button } from '@supertakst/ui-common';
import { useQueryClient } from '@tanstack/react-query';
import { deleteTilstandsrapportDb } from '@utils/localDb';
import { useUserByUid } from '@components/hooks/userProfileQueries';
import * as auth0Utils from '@utils/auth0';

type Props = {
  setOnBehalfOf: (value: string | null) => void;
  onBehalfOfUid: string;
};
const OnBehalfOf = ({ setOnBehalfOf, onBehalfOfUid }: Props) => {
  const { data } = useUserByUid(onBehalfOfUid);
  const queryClient = useQueryClient();
  const exitOnBehalf = async () => {
    await deleteTilstandsrapportDb((await auth0Utils.getDecodedAccessToken())?.sub);
    await del('onBehalfOf');
    await queryClient.clear();
    await queryClient.resetQueries();
    await queryClient.invalidateQueries();
    setOnBehalfOf(null);
    window.location.replace('/');
  };

  return (
    <div className="p-4 text-center bg-yellow-300 text-base">
      <p>
        Du jobber nå på vegne av {data?.firstname} {data?.lastname} ({data?.email})
      </p>
      <Button buttonType="tertiary" onClick={exitOnBehalf} className="inline-block">
        Avslutt på vegne av
      </Button>
    </div>
  );
};

export default OnBehalfOf;
