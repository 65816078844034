import React from 'react';
import { Phrase as IPhrase } from '@supertakst/model-common';
import Phrase from './Phrase';

type Props = {
  phrases: IPhrase[] | undefined;
  type: 'SYSTEM' | 'PERSONAL';
  onClick: (phrase: IPhrase, type: 'SYSTEM' | 'PERSONAL') => void;
};

const PhrasesList = ({ phrases, onClick, type }: Props) => {
  return (
    <div className="flex flex-col space-y-2.5">
      {phrases?.map((phrase) => {
        return <Phrase key={phrase.id} onClick={onClick} phrase={phrase} type={type} />;
      })}
    </div>
  );
};

export default PhrasesList;
