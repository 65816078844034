import { atom } from 'jotai';
import { atomFamily, atomWithStorage } from 'jotai/utils';
import { Tilstandsrapport } from '@supertakst/model-common';

type TilstandrapportGroup = { tilstandsrapportId: string };
export const tilstandsrapportGroupAtomFamily = atomFamily(
  () => atom<string[]>([]),
  (a: TilstandrapportGroup, b: TilstandrapportGroup) =>
    a.tilstandsrapportId === b.tilstandsrapportId
);

type PartNameGroup = {
  tilstandsrapportId: number;
  version: number;
  partId: number;
};
export const partNameAtomFamily = atomFamily(
  () => atom<string>(''),
  (a: PartNameGroup, b: PartNameGroup) =>
    a.tilstandsrapportId === b.tilstandsrapportId &&
    a.partId === b.partId &&
    a.version === b.version
);

type PartAvailabilityGroup = {
  tilstandsrapportId: number;
  version: number;
  partId: number;
};
export const partAvailabilityAtomFamily = atomFamily(
  () => atom<string | undefined>(undefined),
  (a: PartAvailabilityGroup, b: PartAvailabilityGroup) =>
    a.tilstandsrapportId === b.tilstandsrapportId &&
    a.partId === b.partId &&
    a.version === b.version
);

export const availabilityHasChangedAtom = atom<boolean>(false);

/**
 * 1. Background worker må pushe "messages",som under, som må plukkes
 * og legges til i atom'et.
 *
 * 2. "Force push" må lages.
 *
 * 3. Dersom du velger server, wipe alt av lokal cache og refetch fra online.
 *    Dersom local, kjør saveTilstandsrapport med "force"
 */
export type Conflict = {
  serverVersion: Tilstandsrapport;
  updateFromApi: Tilstandsrapport;
};

const conflictsBaseAtom = atomWithStorage<Conflict[]>('conflicts', []);
export const conflictsAtom = atom(
  (get) => get(conflictsBaseAtom),
  (_get, set, newConflicts: Conflict[]) => {
    // Make sure only one conflict of given report is present.
    const newConflictIds = newConflicts.map((c) => c.updateFromApi.id);
    const filteredNewConflicts = newConflicts.filter(
      (c, index) => !newConflictIds.includes(c.updateFromApi.id, index + 1)
    );
    set(conflictsBaseAtom, filteredNewConflicts);
  }
);

// could maybe use export const expandAccordions = atom<boolean>(false);
/// but use with locale storage until we figure out how to do user api call
export const expandAccordions = atomWithStorage('expandAccordions', false);
