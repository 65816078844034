import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { getCheckHeader } from '@utils/modelUtils';
import { CheckSingle, PartAvailability, PartTypes } from '@supertakst/model-common';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import { PartImages } from '@components/common/Images';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';
import {
  validateAnbefalteTiltak,
  validateUbedringskostnader,
} from '@components/common/validations/v2';

interface Props extends BasePartComponentProps<NotAvailableFormData> {
  partType: string;
}

export const NotAvailableForm = ({ defaultValues, doOnSave, partType }: Props) => {
  const [formMethods] = usePartForm<NotAvailableFormData>(
    NotAvailableSchema,
    defaultValues,
    partType,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const availability = formMethods.watch('availability.value');
  useEffect(() => {
    if (availability && availability === 'not_relevant|Ikke relevant') {
      setValue('totalvurdering.value', null, { shouldValidate: true });
      setValue('totalvurdering.tg', null, { shouldValidate: true });
      setValue('utbedringskostnader.value', null, { shouldValidate: true });
      setValue('anbefalteTiltak.value', null, { shouldValidate: true });
    }
  }, [setValue, availability]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes[partType].name} />
        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />
        {availability !== 'not_relevant|Ikke relevant' && (
          <>
            <KonklusjonTiltakKostnader
              konklusjonId="totalvurdering"
              anbefalteTiltakId="anbefalteTiltak"
              utbedringskostnaderId="utbedringskostnader"
              partType="common"
              partVersion={partVersion}
              showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
              showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
            />
          </>
        )}
      </form>
      {availability !== 'not_relevant|Ikke relevant' && (
        <PartImages
          existingImages={getValues('images')}
          setImageValues={setValue}
          heading={PartTypes[partType].name}
        />
      )}
    </FormProvider>
  );
};

export const NotAvailableSchema = Joi.object({
  availability: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('/availability.value', {
      not: `${PartAvailability.NotRelevant.id}|${PartAvailability.NotRelevant.name}`,
      then: Joi.when('..tg', {
        is: 3,
        then: Joi.string().required(),
      })
        .concat(
          Joi.when('..tg', {
            is: 2,
            then: Joi.string().required(),
          })
        )
        .concat(
          Joi.when('..tg', {
            is: -1,
            then: Joi.string().required(),
            otherwise: Joi.any(),
          })
        ),
    }),
    tg: Joi.when('/availability.value', {
      not: `${PartAvailability.NotRelevant.id}|${PartAvailability.NotRelevant.name}`,
      then: Joi.number().integer().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown(true)
  .messages({ '*': 'Dette feltet er påkrevet' });

interface NotAvailableFormData extends BasePartForm {
  totalvurdering?: CheckSingle<string>;
  utbedringskostnader?: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default NotAvailableForm;
