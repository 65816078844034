import React, { useMemo, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { useClickAway, useKey } from 'react-use';
import { twMerge } from 'tailwind-merge';
import { Button } from '@supertakst/ui-common';
import { motion } from 'framer-motion';
import { usePartTypeContext } from '@components/common/PartTypeContext';
import { useTooltips } from '@components/hooks/tooltipQueries';
import useLockBodyScroll from '@components/hooks/useLockBodyScroll';

type IconProps = {
  className?: string;
};

const InfoIcon = ({ className }: IconProps) => {
  return (
    <div
      className={twMerge(
        'shrink-0 h-5 w-5 aspect-square bg-[#F1F6F8]/[0.63] hover:bg-supertakst-blue-200 rounded-full overflow-hidden flex items-center justify-center [&>svg>path]:hover:stroke-white [&>svg>circle]:hover:fill-white',
        className && className
      )}
    >
      <svg width="2" height="9" viewBox="0 0 2 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 4V8" stroke="#1B2467" strokeWidth="2" strokeLinecap="round" />
        <circle cx="1" cy="1" r="1" fill="#1B2467" />
      </svg>
    </div>
  );
};

type TooltipContentProps = {
  header: string;
  text: string;
  closeModal: () => void;
};

const TooltipContent = ({ header, text, closeModal }: TooltipContentProps) => {
  const contentRef = useRef(null);
  useClickAway(contentRef, () => {
    closeModal();
  });
  return (
    <motion.div
      ref={contentRef}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="mt-[10vh] max-w-[90%] lg:max-w-[60%] w-full bg-white rounded drop-shadow-lg  max-h-[80vh] overflow-auto"
    >
      <h2 className="text-xl font-medium text-supertakst-blue-900 flex items-center p-5 border-b border-b-[#020C56]/6 border-solid">
        {header} <InfoIcon className="ml-3" />
      </h2>
      <div className="p-5 pb-10 text-base text-supertakst-blue-900 whitespace-pre-wrap">
        {text}
        <div className="flex justify-center mt-16">
          <Button type="button" buttonType="tertiary" onClick={closeModal}>
            Lukk
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

type Props = {
  header: string;
  id: string;
};

const Tooltip = ({ header, id }: Props) => {
  const { partType } = usePartTypeContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useKey('Escape', () => setIsOpen(false));
  useLockBodyScroll(isOpen);

  const { data: tooltips } = useTooltips();

  const tooltip = useMemo(
    () => tooltips?.find((tooltip) => tooltip.partId === partType?.id && tooltip.checkId === id),
    [tooltips, partType, id]
  );
  if (!tooltip || tooltip.tooltip.length === 0) return null;
  return (
    <>
      <button
        className="mx-3 p-1"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        <InfoIcon />
      </button>
      {isOpen && (
        <Portal>
          <div className="z-40 fixed inset-0 bg-supertakst-blue-900 bg-opacity-75" />
          <div className="z-40 fixed w-screen h-screen inset-0 flex items-start justify-center">
            <TooltipContent
              header={header}
              closeModal={() => setIsOpen(false)}
              text={tooltip.tooltip}
            />
          </div>
        </Portal>
      )}
    </>
  );
};

export default Tooltip;
