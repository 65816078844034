import { tilstandsgrader } from '@utils/tilstandsgrader';
import { Phrase as IPhrase } from '@supertakst/model-common';
import { twMerge } from 'tailwind-merge';

const Phrase = ({
  phrase,
  type,
  onClick,
}: {
  phrase: IPhrase;
  type: 'SYSTEM' | 'PERSONAL';
  onClick: (phrase: IPhrase, type: 'SYSTEM' | 'PERSONAL') => void;
}) => {
  // Note: https://tailwindcss.com/docs/content-configuration#dynamic-class-names
  const getTgClass = (tg) => {
    switch (tg) {
      case -1:
        return 'bg-supertakst-tgIU';
      case 0:
        return 'bg-supertakst-tg0';
      case 1:
        return 'bg-supertakst-tg1';
      case 2:
        return 'bg-supertakst-tg2';
      case 3:
        return 'bg-supertakst-tg3';
    }
  };

  const tgClass = getTgClass(phrase.tg);

  return (
    <button
      onClick={() => onClick(phrase, type)}
      className="border border-solid border-neutral-light-contrast bg-white text-left rounded flex items-stretch overflow-hidden"
    >
      <div className="p-5">{phrase.phrase}</div>
      {phrase.tg !== null && phrase.tg !== undefined && (
        <div
          className={twMerge(
            'ml-auto bg-red-900 text-white flex justify-center items-center text-[0.625rem] min-w-[3.5rem] font-medium',
            tgClass
          )}
        >
          {tilstandsgrader[phrase.tg].label}
        </div>
      )}
    </button>
  );
};

export default Phrase;
