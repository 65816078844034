import { useEffect, useState } from 'react';

const useLockBodyScroll = (initialValue = true) => {
  const [locked, setLocked] = useState(true);

  useEffect(() => {
    setLocked(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (locked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [locked, setLocked]);
};

export default useLockBodyScroll;
