import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FieldError from '@components/common/FieldError';
import TGInput from '@components/common/TGSelector/TGInput';
import CheckHeader from '@components/common/CheckHeader';
import useFieldState from '@components/hooks/useFieldState';

type Props = {
  id: string;
  header?: string;
  className?: string;
  readonly?: boolean;
  tilstandsgrader: Array<-1 | 0 | 1 | 2 | 3>;
};

const TGSelector = ({ id, header, className, tilstandsgrader, readonly = false }: Props) => {
  const { control } = useFormContext();
  const [error, touched] = useFieldState(id);

  return (
    <div className={className} data-test-type="tg">
      {header && <CheckHeader>{header}</CheckHeader>}
      <Controller
        name={id}
        control={control}
        render={({ field: { onChange, onBlur, ref, name, value } }) => {
          return (
            <TGInput
              tilstandsgrader={tilstandsgrader}
              onChange={onChange}
              onBlur={onBlur}
              id={name}
              inputRef={ref}
              value={value}
              readonly={readonly}
            />
          );
        }}
      />
      {touched && error && <FieldError error={error} />}
    </div>
  );
};

export default TGSelector;
