import { createContext, ReactNode, useContext } from 'react';

type PartType =
  | {
      id: string;
      name: string;
    }
  | undefined;

type Props = {
  children: ReactNode;
  partType: PartType;
};

type ContextProps = {
  partType: PartType;
};

const PartTypeContext = createContext<ContextProps | undefined>(undefined);

const PartTypeContextProvider = ({ children, partType }: Props) => {
  return <PartTypeContext.Provider value={{ partType }}>{children}</PartTypeContext.Provider>;
};

export function usePartTypeContext() {
  const context = useContext(PartTypeContext);
  if (context === undefined) {
    throw new Error('usePartTypeContext must be used within a PartTypeContextProvider');
  }
  return context;
}

export default PartTypeContextProvider;
