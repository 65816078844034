import React from 'react';
import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';

type Props = {
  onClick: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  onBlur: () => void;
  label: string;
  selected: boolean;
  renderAsCheckbox?: boolean;
};

const Checkbox = ({ className, selected = false }: { className?: string; selected?: boolean }) => {
  return (
    <span
      className={twMerge(
        'rounded-md block border border-solid border-light-gray w-6 aspect-square flex items-center justify-center',
        selected && 'border-supertakst-blue-900/10',
        className
      )}
    >
      {selected && (
        <svg
          width="11"
          height="9"
          viewBox="0 0 11 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7109 1.8852C11.1214 1.42719 11.0911 0.715549 10.6433 0.295708C10.1955 -0.124133 9.49964 -0.0931922 9.08913 0.364817L3.88946 6.16611L1.94506 3.7798C1.55615 3.30248 0.862527 3.238 0.395824 3.63576C-0.0708795 4.03351 -0.133936 4.7429 0.254983 5.22021L3.00497 8.59521C3.20816 8.84457 3.50676 8.99203 3.82405 8.99969C4.14135 9.00735 4.44642 8.87447 4.66088 8.63519L10.7109 1.8852Z"
            fill="white"
          />
        </svg>
      )}
    </span>
  );
};

const ControlledButton = ({ onClick, onBlur, label, renderAsCheckbox, selected }: Props) => {
  return (
    <button
      onClick={onClick}
      onBlur={onBlur}
      className={classnames(
        selected
          ? 'bg-supertakst-green-300 border-supertakst-blue-900/[0.05] text-white'
          : 'bg-white border-light-gray text-black',
        'font-medium text-center inline-block px-5 select-none h-14 min-w-[7rem] text-center border rounded leading-tight flex justify-center items-center'
      )}
      data-test-checked={selected}
    >
      {renderAsCheckbox && <Checkbox className="mr-3.5" selected={selected} />}
      {label}
    </button>
  );
};

export default ControlledButton;
