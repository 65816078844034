import React from 'react';

type Props = {
  error: any;
};

const FieldError = ({ error }: Props) => {
  const { message } = error;
  return <div className="text-[#FB4242] field-error">{message}</div>;
};

export default FieldError;
