/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useWindowSize } from 'react-use';
import { Image } from '@supertakst/model-common';
import { ArrowsExpandIcon } from '@supertakst/ui-common';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import Textarea from '@components/common/atoms/Textarea';
import Input from '@components/common/atoms/Input';
import { getImage } from '@utils/imageTransforms';

type Props = {
  images: Image[];
  currentImage: Image;
  onThumbClick: (id) => void;
  onSave: (data) => void;
};

const Details = ({ images, currentImage, onThumbClick, onSave }: Props) => {
  const validationSchema = Joi.object({
    images: Joi.array().items(
      Joi.object({
        id: Joi.string().required(),
        description: Joi.string().allow(''),
        includeInReport: Joi.boolean().allow(null),
        md5: Joi.string(),
        url: Joi.string(),
      })
    ),
  });

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
    defaultValues: { images },
  });

  const { control, getValues } = methods;

  const { fields } = useFieldArray({
    control,
    name: 'images',
  });

  const { width } = useWindowSize();
  const sliderWrapperRef = useRef<HTMLDivElement | null>(null);
  const currentImageRef = useRef<HTMLDivElement | null>(null);

  const handleThumbClick = (ev: React.MouseEvent<HTMLDivElement>, image: Image) => {
    const wrapper = sliderWrapperRef.current;
    const margin = 20;
    if (!wrapper) return;

    const wrapperRect = wrapper.getBoundingClientRect();
    const imageRect = ev.currentTarget.getBoundingClientRect();
    const halfImageWidth = imageRect.width / 2;
    const centerScreen = width / 2;
    if (imageRect.x > centerScreen) {
      const offset = imageRect.x + halfImageWidth - centerScreen - wrapperRect.x + margin;
      wrapper.style.transform = `translateX(-${offset}px)`;
    }
    if (imageRect.x < centerScreen && wrapperRect.x < 0) {
      const offset = centerScreen - (imageRect.x + halfImageWidth) + wrapperRect.x - margin;
      wrapper.style.transform = `translateX(${offset}px)`;
    }
    onThumbClick(image);
  };

  useEffect(() => {
    const wrapper = sliderWrapperRef.current;
    const image = currentImageRef.current;
    if (!wrapper || !image) return;
    const wrapperRect = wrapper.getBoundingClientRect();
    const imageRect = image.getBoundingClientRect();
    const halfImageWidth = imageRect.width / 2;
    const centerScreen = width / 2;
    const offset = imageRect.x + halfImageWidth - centerScreen - wrapperRect.x;
    wrapper.style.transform = `translateX(-${offset}px)`;
  }, [currentImageRef, width]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSave)}>
        <div className="fixed inset-0 bg-white overflow-y-auto">
          <div className="fixed inset-0 bg-[#020C56]/80 overflow-y-auto overflow-x-hidden">
            <button
              type="submit"
              className="absolute py-1 right-1/2 md:right-0 md:py-10 md:px-5 text-white"
            >
              Lukk
            </button>

            <div className="flex flex-col h-screen">
              <div className="grow flex justify-center p-7 md:p-10">
                <div className="bg-white w-full md:w-[70%] xl:my-20">
                  <div
                    className="relative h-2/5 bg-cover bg-no-repeat bg-center"
                    style={{ backgroundImage: `url(${getImage(currentImage.id)})` }}
                  >
                    <a
                      className="absolute bg-white w-10 h-10 rounded-full right-4 top-4 flex justify-center items-center"
                      href={currentImage.url}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <ArrowsExpandIcon />
                    </a>
                  </div>
                  <div className="h-3/5 p-5 md:p-10">
                    {fields.map((field, index) => {
                      const id = getValues(`images.${index}.id`);
                      return id === currentImage.id ? (
                        <div key={field.id}>
                          <Input id={`images[${index}].id`} type="hidden" />
                          <Textarea id={`images[${index}].description`} header="Bildebeskrivelse" />
                          <Input
                            type="checkbox"
                            id={`images[${index}].includeInReport`}
                            header="Inkluder i rapport"
                            className="mt-2"
                          />
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
              <div className="h-24 md:h-40 shrink-0 relative my-2 xl:my-5 ml-10">
                <div ref={sliderWrapperRef} className="absolute h-full transition ease-in-out">
                  <div className="flex justify-start items-center gap-4 h-full">
                    {images.map((image) => {
                      return (
                        <div
                          key={image.id}
                          onClick={(ev) => handleThumbClick(ev, image)}
                          className={twMerge(
                            'bg-white aspect-[4/3] h-full w-auto border border-solid rounded-lg border-light-gray p-1 cursor-pointer opacity-30',
                            currentImage.id === image.id && 'opacity-100'
                          )}
                          ref={currentImage.id === image.id ? currentImageRef : null}
                        >
                          <img
                            src={getImage(image.id, 'thumb')}
                            alt={image.description}
                            className="object-cover w-full h-full"
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default Details;
