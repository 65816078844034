import React, { useEffect, useMemo, useRef } from 'react';
import { usePartTypeContext } from '@components/common/PartTypeContext';
import { BookOpenIcon, Button } from '@supertakst/ui-common';
import { Portal } from 'react-portal';
import FullscreenModal from '@components/common/FullscreenModal';
import { useFormContext } from 'react-hook-form';
import { useKey } from 'react-use';
import { Phrase as IPhrase } from '@supertakst/model-common';
import useLockBodyScroll from '@components/hooks/useLockBodyScroll';
import PhrasesList from '../PhrasesList';
import { useSystemPhrases, useUserPhrases } from '@components/hooks/phrasesQueries';
import { registerSystemPhraseUsage } from '@api/phrases';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

type Props = {
  id: string;
  targetId: string;
  targetHeader: string;
  tgId?: string;
  checkHeader?: string;
  buttonContainerStyles?: string;
};

const PHRASE_INTERMEDIATE_TARGET = 'phraseInput';

const Phrases = ({
  id,
  targetId,
  targetHeader,
  tgId,
  checkHeader,
  buttonContainerStyles,
}: Props) => {
  const { partType } = usePartTypeContext();
  const [open, setOpen] = React.useState(false);
  const { setValue: setOuterValue, getValues: getOuterValues } = useFormContext();
  const intermediateEl = useRef<HTMLTextAreaElement | null>(null);
  useKey('Escape', () => setOpen(false));
  useLockBodyScroll(open);
  const currentValue = getOuterValues(targetId);
  const { setValue, getValues, reset, register, handleSubmit } = useForm({
    defaultValues: useMemo(() => {
      return {
        [PHRASE_INTERMEDIATE_TARGET]: currentValue,
      };
    }, [currentValue]),
  });

  const { ref, ...rest } = register(PHRASE_INTERMEDIATE_TARGET);

  // Handle change of currentValue
  useEffect(() => {
    reset({ [PHRASE_INTERMEDIATE_TARGET]: currentValue });
  }, [currentValue]);

  const scrollTextareaToBottom = () => {
    if (intermediateEl.current) {
      intermediateEl.current.scrollTop = intermediateEl.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (open) {
      scrollTextareaToBottom();
    }
  }, [open]);

  const onSubmit = (data) => {
    toast.success(`Frase lagt til i "${targetHeader}"`);
    setOuterValue(targetId, data[PHRASE_INTERMEDIATE_TARGET], {
      shouldTouch: true,
      shouldValidate: true,
      shouldDirty: true,
    });
    setOpen(false);
  };

  const { data: userPhrases } = useUserPhrases();
  const { data: systemPhrases } = useSystemPhrases();

  const fieldUserPhrases = useMemo(
    () => userPhrases?.filter((phrase) => phrase.partId === partType?.id && phrase.checkId === id),
    [userPhrases, partType, id]
  );

  const fieldSystemPhrases = useMemo(
    () =>
      systemPhrases?.filter((phrase) => phrase.partId === partType?.id && phrase.checkId === id),
    [systemPhrases, partType, id]
  );

  // TODO: Changes go here. Pull value from phraseInput and set it to targetId
  const doOnClick = (phrase: IPhrase, type: 'SYSTEM' | 'PERSONAL') => {
    const options = {
      shouldTouch: true,
      shouldValidate: true,
      shouldDirty: true,
    };
    if (type === 'SYSTEM') {
      registerSystemPhraseUsage(phrase).catch((err) => {
        console.warn('Error registering system phrase usage', err);
      });
    }

    const existingText = getValues(PHRASE_INTERMEDIATE_TARGET);
    const newText = existingText ? `${existingText}\n\n${phrase.phrase}` : phrase.phrase;

    setValue(PHRASE_INTERMEDIATE_TARGET, newText, options);
    if (tgId) {
      const currentTgValue = getOuterValues(tgId);
      const currentTg =
        currentTgValue !== null && currentTgValue !== undefined ? currentTgValue : -1;
      const { tg: phraseTg = -1 } = phrase;
      const currentTgIsHigher = currentTg >= phraseTg;
      if (!currentTgIsHigher) {
        setOuterValue(tgId, phrase.tg, options);
      }
    }
    scrollTextareaToBottom();
  };

  const hasUserPhrases = fieldUserPhrases && fieldUserPhrases?.length > 0;
  const hasSystemPhrases = fieldSystemPhrases && fieldSystemPhrases?.length > 0;

  return (
    <>
      {(hasUserPhrases || hasSystemPhrases) && (
        <div className={twMerge('border-t', buttonContainerStyles)}>
          <button
            type="button"
            className="flex text-xs font-medium items-center p-4"
            onClick={() => setOpen(true)}
          >
            <BookOpenIcon className="scale-75 mr-1.5" />
            <span className="underline">Legg til fraser</span>
          </button>
        </div>
      )}
      {open && (
        <Portal>
          <FullscreenModal.Container
            onClose={() => setOpen(false)}
            title="Fraser"
            titleIcon={BookOpenIcon}
          >
            <div className="pb-[380px] sm:pb-[460px]">
              <div className="lg:max-w-[70%] mx-auto px-4">
                <h3 className="text-supertakst-blue-900 text-xl font-medium text-center">
                  {partType?.name} {checkHeader && `– ${checkHeader}`}
                </h3>

                <div className="flex space-x-6 mt-12">
                  {hasUserPhrases && (
                    <div className="flex-1">
                      <h2 className="text-base text-supertakst-blue-900 font-medium mb-2">
                        Brukerfraser
                      </h2>
                      <PhrasesList phrases={fieldUserPhrases} onClick={doOnClick} type="PERSONAL" />
                    </div>
                  )}

                  {hasSystemPhrases && (
                    <div className="flex-1">
                      <h2 className="text-base text-supertakst-blue-900 font-medium mb-2">
                        Systemfraser
                      </h2>
                      <PhrasesList phrases={fieldSystemPhrases} onClick={doOnClick} type="SYSTEM" />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="rounded-t-xl overflow-hidden max-h-[320px] sm:max-h-[400px] h-full fixed bottom-0 left-0 right-0 bg-white">
              <div className="bg-supertakst-blue-900 text-white p-4 text-xl font-bold">
                {targetHeader}
              </div>
              <div className="p-4 pb-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <textarea
                    {...rest}
                    ref={(e) => {
                      ref(e);
                      intermediateEl.current = e;
                    }}
                    className="text-base min-h-[6rem] sm:min-h-[10rem] appearance-none border border-[#303030]/20 rounded w-full py-2 px-5 text-supertakst-blue-900 bg-supertakst-gray-50 focus:outline-none focus:shadow-outline"
                  ></textarea>
                  <Button type="submit" className="mx-auto mt-12">
                    Lagre og lukk
                  </Button>
                </form>
              </div>
            </div>
          </FullscreenModal.Container>
        </Portal>
      )}
    </>
  );
};

export default Phrases;
