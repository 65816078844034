import React, { useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import Header from '@components/common/Header';
import FieldError from '@components/common/FieldError';
import useFieldState from '@components/hooks/useFieldState';
import Asterix from '@components/common/Asterix';

type Props = {
  id: string;
  className?: string;
  header?: string;
};

const Textarea = ({ id, header, className }: Props) => {
  const { register } = useFormContext();
  const [error] = useFieldState(id);
  const { ref, ...rest } = register(id);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const resizeTextarea = (element: HTMLTextAreaElement) => {
    if (element) {
      element.style.height = 'auto';
      element.style.height = `${element.scrollHeight}px`;
    }
  };

  const textAreaWatch = useWatch({ name: id });

  const textarea = (
    <textarea
      data-test-type="textarea"
      data-check-id={id}
      className="text-base min-h-[6rem] appearance-none border border-[#303030]/20 rounded w-full py-2 px-5 text-supertakst-blue-900 bg-supertakst-gray-50 focus:outline-none focus:shadow-outline overflow-hidden"
      {...rest}
      ref={(e) => {
        ref(e);
        inputRef.current = e;
      }}
    />
  );

  useEffect(() => {
    if (inputRef?.current) {
      resizeTextarea(inputRef.current);
    }
  }, [textAreaWatch]);

  return (
    <div className={className}>
      {header ? (
        <Header as="label">
          <span className="mb-2.5 inline-block">{header}</span> {error && <Asterix />}
          {textarea}
        </Header>
      ) : (
        textarea
      )}
      {error && <FieldError error={error} />}
    </div>
  );
};

export default Textarea;
