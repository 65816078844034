import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, UploadIcon } from '@supertakst/ui-common';
import { useNetworkState } from 'react-use';

type Props = {
  onUploadClick: (files) => void;
  onCancelClick: () => void;
};

type FileType = {
  accepted: Array<any>;
  rejected: Array<any>;
};

const Upload = ({ onUploadClick, onCancelClick }: Props) => {
  const [files, setFiles] = useState<FileType>({ accepted: [], rejected: [] });
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { online } = useNetworkState();

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setFiles({
        accepted: [...files.accepted, ...acceptedFiles],
        rejected: [...files.rejected, ...rejectedFiles],
      });
    },
    [files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxSize: 10485760, // 10MB
    useFsAccessApi: false,
    onDrop,
  });

  const errorMessages = {
    'file-too-large': 'for stor, maks 10MB',
    'file-invalid-type': 'ukjent filtype, kun png og jpg er tillatt',
  };

  const renderFiles = (fileArray) => {
    const filesRendered = fileArray.map((file) => {
      if (file.errors) {
        return (
          <li key={file.file.name}>
            {file.file.name} -{' '}
            <span className="text-supertakst-red-900">
              ({errorMessages[file.errors[0].code] ?? 'ukjent feil'})
            </span>
          </li>
        );
      } else {
        return <li key={file.name}>{file.name}</li>;
      }
    });
    return <ul>{filesRendered}</ul>;
  };

  const handleUpload = () => {
    setIsUploading(true);
    onUploadClick(files.accepted);
  };

  if (!online)
    return <p className="text-[#FB4242] text-center">Du må være online for å laste opp bilder</p>;

  return (
    <div className="w-full md:max-w-[70%] flex flex-col justify-center items-center mx-auto gap-10">
      <div
        {...getRootProps()}
        className="p-4 md:p-24 lg:p-32 xl:p-60 border-dotted border-4 flex flex-col items-center gap-8"
      >
        <input {...getInputProps()} />
        <div className="text-supertakst-blue-900 flex gap-2">
          {isDragActive ? 'Slipp!' : 'Dra og slipp eller trykk for å velge bilder'}
          {``}
          <UploadIcon />
        </div>
        {files.accepted.length > 0 && (
          <div className="self-start">
            <p className="font-medium">Filer klare til opplastning:</p>
            {renderFiles(files.accepted)}
          </div>
        )}
        {files.rejected.length > 0 && (
          <div className="self-start">
            <p className="font-medium">Filer som ikke bli lastet opp:</p>
            {renderFiles(files.rejected)}
          </div>
        )}
      </div>

      <div className="flex flex-col gap-6 items-center">
        <Button disabled={files.accepted.length === 0 || isUploading} onClick={handleUpload}>
          Last opp bilder
        </Button>
        <Button disabled={isUploading} onClick={onCancelClick} buttonType="tertiary">
          Avbryt
        </Button>
      </div>
    </div>
  );
};

export default Upload;
