import axios from 'axios';
import { Image } from '@supertakst/model-common';
import * as auth0Utils from '@utils/auth0';

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  timeout: 120000,
});

export const uploadImage = async (
  file: File,
  signData: { key: string; timestamp: string; signature: string },
  params?: { [key: string]: string }
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', `${process.env.NEXT_PUBLIC_CLOUDINARY_PRESET}`);
  formData.append('api_key', signData.key);
  formData.append('timestamp', signData.timestamp);
  formData.append('signature', signData.signature);
  if (params) {
    for (const [key, value] of Object.entries(params)) {
      formData.append(key, value);
    }
  }
  const { data } = await client.post(
    `https://api.cloudinary.com/v1_1/${process.env.NEXT_PUBLIC_CLOUDINARY_ACCOUNT}/image/upload`,
    formData
  );

  return {
    id: data.public_id,
    url: data.secure_url,
  };
};

export const deleteImages = async (images: Image[]) => {
  const accessToken = await auth0Utils.getTokenSilently();
  const reqConfig = {
    headers: { Authorization: `Bearer ${accessToken}` },
    data: images,
  };
  const { data } = await client.delete('/images/delete', reqConfig);
  return data;
};

export const generateSignature = async (params?: { [key: string]: string }) => {
  const accessToken = await auth0Utils.getTokenSilently();
  const reqConfig = {
    headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' },
    timeout: 20000,
  };
  const { data } = await client.post('/images/sign', JSON.stringify(params), reqConfig);
  return data;
};
