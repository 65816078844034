import { useQuery } from '@tanstack/react-query';
import { Tooltip } from '@supertakst/model-common';
import { fetchAllTooltips } from '@api/tooltips';

export const tooltipQueryKeys = {
  tooltips: () => ['tooltips'],
};

export function useTooltips(options = {}) {
  return useQuery<Tooltip[]>(
    tooltipQueryKeys.tooltips(),
    async () => {
      return await fetchAllTooltips();
    },
    options
  );
}
