import Joi from 'joi';
export const validateAnbefalteTiltak = (key = 'totalvurdering') =>
  Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (typeof value !== 'boolean' && [-1, 2, 3].includes(state[key].tg)) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.any().presence('optional'),
  });

export const validateTotalvurdering = Joi.object({
  value: Joi.when('..tg', {
    is: 3,
    then: Joi.string().required(),
    otherwise: Joi.any(),
  })
    .concat(
      Joi.when('..tg', {
        is: 2,
        then: Joi.string().required(),
        otherwise: Joi.any(),
      })
    )
    .concat(
      Joi.when('..tg', {
        is: -1,
        then: Joi.string().required(),
        otherwise: Joi.any(),
      })
    ),
  tg: Joi.number().integer().required(),
  comment: Joi.any().presence('optional'),
  touched: Joi.boolean().presence('optional'),
});

export const validateUbedringskostnader = (key = 'totalvurdering') =>
  Joi.object({
    value: Joi.when(Joi.ref(`/${key}.tg`), { is: 3, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.any().presence('optional'),
  }).presence('optional');
