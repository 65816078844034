import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TGSelector from '@components/common/TGSelector';
import Textarea from '@components/common/atoms/Textarea';
// TODO: Should this be moved to atom? If so, the same goes for the other Check* components that has a common component.
import TextInput from '@components/common/CheckText/TextInput';
import BoolInput from '@components/common/CheckBoolean/BoolInput';
import Phrases from '@components/common/Phrases/v2';
import { PartTypes, StandalonePartTypes } from '@supertakst/model-common';
import { getCheckHeader } from '@utils/modelUtils';

export type Alternative = {
  value: string;
  label: string;
};

type PhraseTarget = {
  id: string;
  header: string;
  tg?: string;
};

type Props = {
  partVersion: number;
  partType: keyof typeof PartTypes | keyof typeof StandalonePartTypes | 'common';

  konklusjonId?: string;
  utbedringskostnaderId?: string;
  anbefalteTiltakId?: string;

  showAnbefalteTiltak?: boolean;
  showUtbedringskostnader?: boolean;

  utbedringskostnaderAlternatives?: Alternative[];
  hasTg?: boolean;
  tgReadonly?: boolean;
  tilstandsgrader?: Array<-1 | 0 | 1 | 2 | 3>;
  phrasesTarget?: PhraseTarget;
};

const KonklusjonTiltakKostnader = ({
  partVersion,
  partType,
  konklusjonId = 'totalvurdering',
  anbefalteTiltakId = 'anbefalteTiltak',
  utbedringskostnaderId = 'utbedringskostnader',
  showAnbefalteTiltak = false,
  utbedringskostnaderAlternatives = [
    { value: '0', label: 'Ingen umiddelbar kostnad' },
    { value: '10000', label: 'Under 10 000' },
    { value: '10000-50000', label: '10 000 - 50 000' },
    { value: '50000-150000', label: '50 000 - 150 000' },
    { value: '150000-300000', label: '150 000 - 300 000' },
    { value: '300000', label: 'Over 300 000' },
  ],
  showUtbedringskostnader = false,
  hasTg = true,
  tgReadonly = false,
  tilstandsgrader = [-1, 0, 1, 2, 3],
  phrasesTarget = {
    header: 'Oppsummering',
    id: 'totalvurdering.value',
    tg: 'totalvurdering.tg',
  },
}: Props) => {
  const { control, getValues } = useFormContext();
  const conclusionName = `${konklusjonId}.value`;
  const tgName = `${konklusjonId}.tg`;
  const kostnaderName = `${utbedringskostnaderId}.value`;
  const anbefalteTiltakName = `${anbefalteTiltakId}.value`;
  const anbefalteTiltakComment = `${anbefalteTiltakId}.comment`;

  const konklusjonHeader = getCheckHeader(partVersion, partType, konklusjonId);
  const anbefalteTiltakHeader = getCheckHeader(partVersion, partType, anbefalteTiltakId);
  const utbedringskostnaderHeader = getCheckHeader(partVersion, partType, utbedringskostnaderId);

  return (
    <div className="rounded-t-xl overflow-hidden">
      <div className="bg-supertakst-blue-900 text-white p-4 text-xl font-bold">
        {konklusjonHeader}
      </div>
      <div className="p-4 bg-white" data-test-id="check-card">
        {hasTg && (
          <TGSelector id={tgName} tilstandsgrader={tilstandsgrader} readonly={tgReadonly} />
        )}
        <section className="mt-8 border-t -mx-4 p-4">
          <h3 className="text-xl font-bold text-supertakst-blue-900 mb-5 mt-2">Oppsummering</h3>
          <Textarea className="mt-6" id={conclusionName} />

          <Phrases
            id={konklusjonId}
            targetId={conclusionName}
            targetHeader={phrasesTarget.header}
            tgId={phrasesTarget.tg}
            checkHeader={phrasesTarget.header}
            buttonContainerStyles="border-t-0 -ml-4 pb-0"
          />
        </section>
        {showAnbefalteTiltak && (
          <section className="mt-8 border-t -mx-4 p-4">
            <h3 className="text-xl font-bold text-supertakst-blue-900 mb-5 mt-2">
              {anbefalteTiltakHeader}
            </h3>
            <Controller
              name={anbefalteTiltakName}
              control={control}
              render={({ field: { onChange, onBlur, ref, name, value } }) => {
                return (
                  <BoolInput
                    onChange={onChange}
                    onBlur={onBlur}
                    id={name}
                    inputRef={ref}
                    value={value}
                  />
                );
              }}
            />
            {getValues(anbefalteTiltakName) === true && (
              <>
                <Textarea
                  className="mt-6"
                  header="Vurdering og beskrivelse"
                  id={anbefalteTiltakComment}
                />
                <Phrases
                  id={anbefalteTiltakId}
                  targetId={anbefalteTiltakComment}
                  targetHeader="Vurdering og beskrivelse"
                  tgId={phrasesTarget.tg}
                  checkHeader={anbefalteTiltakHeader}
                  buttonContainerStyles="border-t-0 -ml-4 pb-0"
                />
              </>
            )}
          </section>
        )}

        {showUtbedringskostnader && (
          <section className="mt-8 border-t -mx-4 p-4">
            <h3 className="text-xl font-bold text-supertakst-blue-900 mb-5 mt-2">
              {utbedringskostnaderHeader}
            </h3>
            <Controller
              name={kostnaderName}
              control={control}
              render={({ field: { onChange, onBlur, ref, name, value } }) => {
                return (
                  <TextInput
                    onChange={onChange}
                    onBlur={onBlur}
                    id={name}
                    inputRef={ref}
                    value={value}
                    disableUncheck={false}
                    alternatives={utbedringskostnaderAlternatives}
                  />
                );
              }}
            />
            <Phrases
              id={utbedringskostnaderId}
              targetId={conclusionName}
              targetHeader={phrasesTarget.header}
              tgId={phrasesTarget.tg}
              checkHeader={phrasesTarget.header}
              buttonContainerStyles="border-t-0 -ml-4 pb-0"
            />
          </section>
        )}
      </div>
    </div>
  );
};

export default KonklusjonTiltakKostnader;
