import React, { useState } from 'react';
import { Portal } from 'react-portal';
import { Id, toast } from 'react-toastify';
import { useKey } from 'react-use';
import { useRouter } from 'next/router';
import { Image } from '@supertakst/model-common';
import { Button, CameraIcon } from '@supertakst/ui-common';
import FullscreenModal from '@components/common/FullscreenModal';
import { deleteImages, generateSignature, uploadImage } from '@api/image';
import Browser from './Browser';
import Details from './Details';
import Upload from './Upload';

type Props = {
  heading?: string;
  existingImages: Image[] | [];
  setImageValues: (string, object, options) => void;
};

const PartImages = ({ heading, existingImages = [], setImageValues }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<string>(existingImages.length > 0 ? 'browse' : 'upload');
  const [images, setImages] = useState<Image[] | []>(existingImages);
  const [selectedImages, setSelectedImages] = useState<Image[] | undefined>();
  const [currentImage, setCurrentImage] = useState<Image>();
  const toastId = React.useRef<Id | null>(null);
  const router = useRouter();

  useKey('Escape', () => setOpen(false));

  const setValueOptions = { shouldTouch: true, shouldValidate: true, shouldDirty: true };

  const handleThumbClick = (image: Image) => {
    setCurrentImage(image);
    if (mode !== 'details') {
      setMode('details');
    }
  };

  const handleOnSave = (batchImages) => {
    const newImages = images.map((image) => {
      return batchImages.images.find((i) => i.id === image.id) ?? image;
    });
    setImageValues('images', newImages, setValueOptions); // update befaring form context
    setImages(newImages);
    setSelectedImages(undefined);
    setMode('browse');
  };

  const handleUpload = async (imageUploads: File[]) => {
    if (imageUploads.length === 0) return;

    const params = {
      folder: `${process.env.NEXT_PUBLIC_CLOUDINARY_PRESET}/images/tilstandsrapporter/${router.query.id}`,
    };

    const signData = await generateSignature(params);

    toastId.current = toast(
      `Laster opp ${imageUploads.length} ${imageUploads.length > 1 ? 'filer' : 'fil'}`,
      { isLoading: true }
    );
    const uploadResponses = await Promise.allSettled(
      imageUploads.map((image) => uploadImage(image, signData, params))
    );
    let fulfilled: Image[] = [];
    let rejected: string[] = [];
    const numExisting = existingImages.length;
    uploadResponses.forEach((item, idx) => {
      if (item.status === 'fulfilled') {
        fulfilled.push(item.value);
        setImageValues(`images[${numExisting + idx}]`, item.value, setValueOptions); // update befaring form context
      } else {
        rejected.push(item.reason);
      }
    });

    setImages([...images, ...fulfilled]);
    setMode('browse');
    if (fulfilled.length) {
      toast.update(toastId.current, {
        render: `${fulfilled.length} ${fulfilled.length > 1 ? 'filer' : 'fil'} lastet opp!`,
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
      });
    }
    if (rejected.length) {
      if (fulfilled.length === 0) {
        toast.dismiss(toastId.current);
      }
      toast.error(
        `${rejected.length} ${rejected.length > 1 ? 'filer' : 'fil'} feilet og ble ikke lastet opp`
      );
    }
  };

  const handleCancelUpload = () => {
    images.length === 0 ? setOpen(false) : setMode('browse');
  };

  const onCloseModal = () => {
    setOpen(false);
    images?.length ? setMode('browse') : setMode('upload');
  };

  const handleBatchAction = async (action: string, batchImages: Image[]) => {
    if (action === 'delete') {
      const ids = batchImages.map((i) => i.id);
      const newImages = images.filter((i) => ids.includes(i.id) === false);
      await deleteImages(batchImages);
      setSelectedImages(undefined);
      setImages(newImages);
      setImageValues('images', newImages, setValueOptions); // update befaring form context
      if (newImages.length === 0) setMode('upload');
      toast.success(
        `${batchImages.length} ${batchImages.length > 1 ? 'filer' : 'fil'} ble slettet`
      );
    }
    if (action === 'edit') {
      setSelectedImages(batchImages);
      setCurrentImage(batchImages[0]);
      setMode('details');
    }
  };

  return (
    <>
      <Button
        type="button"
        buttonType="secondary"
        className="p-0 h-16 w-16 rounded-full mt-5 bg-white lg:fixed lg:bottom-0 lg:right-0 lg:m-5"
        onClick={() => setOpen(true)}
      >
        <CameraIcon />
      </Button>
      {open && (
        <Portal>
          <FullscreenModal.Container onClose={onCloseModal} title={heading ?? 'Bilder'}>
            <div className="pb-28">
              {mode === 'upload' && (
                <Upload onUploadClick={handleUpload} onCancelClick={handleCancelUpload} />
              )}
              {mode === 'browse' && images.length > 0 && (
                <Browser
                  images={images}
                  onThumbClick={handleThumbClick}
                  onUploadClick={() => setMode('upload')}
                  onSelectedAction={handleBatchAction}
                />
              )}
              {mode === 'details' && currentImage && (
                <Details
                  images={selectedImages ?? images}
                  currentImage={currentImage}
                  onThumbClick={(value) => handleThumbClick(value)}
                  onSave={handleOnSave}
                />
              )}
            </div>
          </FullscreenModal.Container>
        </Portal>
      )}
    </>
  );
};

export default PartImages;
