import React from 'react';

type Props = {
  onClose: () => void;
  title: string;
  titleIcon?: React.ElementType;
  children: React.ReactNode;
};

const FullscreenModal = ({ onClose, title, children, titleIcon }: Props) => {
  const Icon = titleIcon;
  return (
    <div className="fixed inset-0 bg-[#F7F8FC] overflow-y-auto z-50">
      <div className="flex justify-center mb-28 mt-10">
        <button
          onClick={onClose}
          className="fixed left-4 text-base text-supertakst-blue-900 font-medium mb-2"
        >
          Lukk
        </button>
        <div className="grow flex justify-center">
          <h2 className="color-supertakst-blue-900 text-base text-center m-0 font-medium flex">
            {Icon && <Icon className="mr-2.5 scale-75" />}
            {title}
          </h2>
        </div>
      </div>
      {children}
    </div>
  );
};

export default FullscreenModal;
