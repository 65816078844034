import axios from 'axios';
import { onlineManager } from '@tanstack/react-query';
import { getSystemPhrases, getUserPhrases, setSystemPhrases, setUserPhrases } from '@utils/localDb';
import { Phrase } from '@supertakst/model-common';
import * as auth0Utils from '@utils/auth0';

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  timeout: 20000,
});
client.interceptors.request.use(async (reqConfig) => {
  const accessToken = await auth0Utils.getTokenSilently();
  return {
    ...reqConfig,
    headers: { ...reqConfig.headers, Authorization: `Bearer ${accessToken}` },
  };
});

export const fetchAllUserPhrases = async (): Promise<Phrase[]> => {
  if (onlineManager.isOnline()) {
    const { data } = await client.get(`/phrases`);
    await setUserPhrases(data);
    return data;
  } else {
    return await getUserPhrases();
  }
};

export const fetchAllSystemPhrases = async (): Promise<Phrase[]> => {
  if (onlineManager.isOnline()) {
    const { data } = await client.get(`/phrases/system`);
    await setSystemPhrases(data);
    return data;
  } else {
    return await getSystemPhrases();
  }
};

export const registerSystemPhraseUsage = async (phrase: Phrase) => {
  if (onlineManager.isOnline()) {
    await client.put(`/phrases/system/${phrase.id}/use`);
  }
};
