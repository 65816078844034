import '../styles/globals.css';
import type { AppProps } from 'next/app';
import Workers from '@components/Workers';
import SetIdToken from '@components/SetIdToken';
import { Auth0Provider } from '@auth0/auth0-react';
import dynamic from 'next/dynamic';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'jotai';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import matchAll from 'string.prototype.matchall';
import { Analytics } from '@vercel/analytics/react';
import { useNetworkState } from 'react-use';
import { useRouter } from 'next/router';
import { del, get, set } from 'idb-keyval';
import { parseTilstandsrapportIdFromQuery } from '@utils/modelUtils';
import { useEffect, useState } from 'react';
import Offline from '@components/Offline';
import OnBehalfOf from '@components/OnBehalfOf';
import Head from 'next/head';
import { IndexedDbCache } from '@utils/auth0';
import { useHasNewDeploy } from 'next-deploy-notifications';
import NewVersion from '@components/NewVersion';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/nextjs';

matchAll.shim();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      refetchOnReconnect: false,
    },
    mutations: {
      networkMode: 'always',
    },
  },
});

const logRocketInitState = { initializing: false };
function initLogRocket() {
  const sentryEnv = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'development';
  const sentryRelease = `befaring-app@${process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || '0.0.0'}`;
  const isOnServer = typeof window === 'undefined';
  const isNotDev = process.env.NODE_ENV !== 'development';
  if (!logRocketInitState.initializing && !isOnServer && isNotDev) {
    logRocketInitState.initializing = true;
    LogRocket.init('j9yfqq/supertakst');
    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.addEventProcessor((event) => ({
          ...event,
          environment: sentryEnv,
          release: sentryRelease,
        }));
        scope.setExtra('sessionURL', sessionURL);
      });
    });
  }
}

function App({ Component, pageProps }: AppProps) {
  initLogRocket();

  const isOnServer = typeof window === 'undefined';
  const { hasNewDeploy } = useHasNewDeploy();
  const { online } = useNetworkState();
  const router = useRouter();
  const { query, isReady, pathname } = router;
  const [onBehalfOfState, setOnBehalfOfState] = useState<string | null>(null);

  useEffect(() => {
    if (!isReady) return;
    if (query) {
      const { id, onBehalfOf } = query;

      // If we have id, this means that we're working on a tilstandsrapport.
      // Set this in indexeddb, so we can avoid syncing the report in workers.
      // If not, delete entry in indexeddb.
      if (id) {
        const tilstandsrapportId = parseTilstandsrapportIdFromQuery(id);
        set('currentTilstandsrapportId', tilstandsrapportId);
      } else {
        del('currentTilstandsrapportId');
      }
      if (onBehalfOf) {
        // Remove query param onBehalfOf after setting it in indexeddb, and redirect to same page.
        set('onBehalfOf', onBehalfOf);
        delete router.query.onBehalfOf;
        router.replace({ pathname, query }, undefined, { shallow: true });
      }
    }
  }, [query, isReady]);

  useEffect(() => {
    (async () => {
      const onBehalfOf = await get('onBehalfOf');
      setOnBehalfOfState(onBehalfOf);
    })();
  }, [isReady]);

  if (isOnServer) return null;
  return (
    <>
      <Head>
        <title>Supertakst Befaring</title>
        <meta name="description" content="Supertakst Befaring" />
      </Head>
      <Auth0Provider
        domain={`${process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL}`}
        clientId={`${process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}`}
        audience={`${process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}`}
        redirectUri={window.location.origin}
        cache={IndexedDbCache}
        useRefreshTokens={true}
        onRedirectCallback={() => {
          return router.replace('/');
        }}
      >
        <SetIdToken>
          <Provider>
            <QueryClientProvider client={queryClient}>
              {hasNewDeploy && <NewVersion />}
              {!online && <Offline />}
              {onBehalfOfState && (
                <OnBehalfOf
                  setOnBehalfOf={(value) => setOnBehalfOfState(value)}
                  onBehalfOfUid={onBehalfOfState}
                />
              )}
              <Component {...pageProps} />
              <ToastContainer theme="colored" autoClose={2000} />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
            <Workers />
          </Provider>
        </SetIdToken>
      </Auth0Provider>
      <Analytics />
    </>
  );
}

export default dynamic(() => Promise.resolve(App), {
  ssr: false,
});
