import React, { createContext, ReactNode, useContext } from 'react';
import useFieldState from '@components/hooks/useFieldState';
import { twMerge } from 'tailwind-merge';

type Props = {
  children: ReactNode;
  id: string;
};

type ContextProps = {
  id: string | undefined;
};

const CheckCardIdContext = createContext<ContextProps | undefined>(undefined);

const CheckCardItem = ({ children, id }: Props) => {
  const [errors] = useFieldState(id);
  return (
    <CheckCardIdContext.Provider value={{ id }}>
      <div
        className={twMerge(
          'bg-white border border-solid rounded text-supertakst-blue-900',
          errors ? 'hasErrors' : 'isComplete'
        )}
        data-test-id="check-card"
        data-check-id={id}
      >
        {children}
      </div>
    </CheckCardIdContext.Provider>
  );
};

export function useCheckCardIdContext() {
  const context = useContext(CheckCardIdContext);
  if (context === undefined) {
    throw new Error('useCheckCardIdContext must be used within a AccordionIdContextProvider');
  }
  return context;
}

export default CheckCardItem;
