import React from 'react';

const Offline = () => {
  return (
    <div className="left-1/2 -translate-x-1/2 bottom-12 fixed">
      <div className="border-solid border flex items-center py-2.5 px-5 rounded-full bg-invalid border-invalid-light-contrast text-invalid-dark-contrast">
        <span className="text-sm mr-4 whitespace-nowrap">Du jobber nå offline</span>
        <div className="shrink-0 h-5 w-5 aspect-square border border-solid border-invalid-light-contrast rounded-full overflow-hidden flex items-center justify-center">
          <svg
            className="[&>path]:stroke-invalid-dark-contrast [&>circle]:fill-invalid-dark-contrast"
            width="2"
            height="9"
            viewBox="0 0 2 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 4V8" stroke="#1B2467" strokeWidth="2" strokeLinecap="round" />
            <circle cx="1" cy="1" r="1" fill="#1B2467" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Offline;
