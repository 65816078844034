import { Ambita, modelUtils } from '@supertakst/model-common';

export const readableAddress = (address: Ambita.Address) => {
  return `${address.street?.streetName} ${address.ident.addressNumber} ${
    address.ident.letter || ''
  }, ${modelUtils.formatPostalCode(address.street?.postalCode ?? '')} ${
    address.street?.postalPlace
  }`;
};

export const streetAddress = (address?: Ambita.Address) => {
  if (!address) return;
  return `${address.street?.streetName} ${address.ident.addressNumber}${
    address.ident.letter ? ` ${address.ident.letter}` : ''
  }`;
};
