import React, { useMemo } from 'react';
import { usePartTypeContext } from '@components/common/PartTypeContext';
import { BookOpenIcon, Button } from '@supertakst/ui-common';
import { Portal } from 'react-portal';
import FullscreenModal from '@components/common/FullscreenModal';
import { useFormContext } from 'react-hook-form';
import { useKey } from 'react-use';
import { Phrase as IPhrase } from '@supertakst/model-common';
import useLockBodyScroll from '@components/hooks/useLockBodyScroll';
import PhrasesList from '../PhrasesList';
import { useSystemPhrases, useUserPhrases } from '@components/hooks/phrasesQueries';
import { registerSystemPhraseUsage } from '@api/phrases';

type Props = {
  id: string;
  textId: string;
  hasTg: boolean;
  tgId: string;
  checkHeader?: string;
};

const Phrases = ({ id, textId, tgId, hasTg, checkHeader }: Props) => {
  const { partType } = usePartTypeContext();
  const [open, setOpen] = React.useState(false);
  const { setValue, getValues } = useFormContext();
  useKey('Escape', () => setOpen(false));
  useLockBodyScroll(open);

  const { data: userPhrases } = useUserPhrases();
  const { data: systemPhrases } = useSystemPhrases();

  const fieldUserPhrases = useMemo(
    () => userPhrases?.filter((phrase) => phrase.partId === partType?.id && phrase.checkId === id),
    [userPhrases, partType, id]
  );

  const fieldSystemPhrases = useMemo(
    () =>
      systemPhrases?.filter((phrase) => phrase.partId === partType?.id && phrase.checkId === id),
    [systemPhrases, partType, id]
  );

  const doOnClick = (phrase: IPhrase, type: 'SYSTEM' | 'PERSONAL') => {
    const options = {
      shouldTouch: true,
      shouldValidate: true,
      shouldDirty: true,
    };
    if (type === 'SYSTEM') {
      registerSystemPhraseUsage(phrase).catch((err) => {
        console.warn('Error registering system phrase usage', err);
      });
    }

    const existingText = getValues(textId);
    const newText = existingText ? `${existingText}\n\n${phrase.phrase}` : phrase.phrase;

    setValue(textId, newText, options);
    const currentTg = getValues(tgId);
    const hasHigherTg = currentTg && phrase.tg && currentTg > phrase.tg;
    if (hasTg && !hasHigherTg) {
      setValue(tgId, phrase.tg, options);
    }
    setOpen(false);
  };

  const hasUserPhrases = fieldUserPhrases && fieldUserPhrases?.length > 0;
  const hasSystemPhrases = fieldSystemPhrases && fieldSystemPhrases?.length > 0;

  return (
    <>
      {(hasUserPhrases || hasSystemPhrases) && (
        <Button
          type="button"
          buttonType="secondary"
          icon={BookOpenIcon}
          className="mt-5"
          onClick={() => setOpen(true)}
        >
          Fraser
        </Button>
      )}
      {open && (
        <Portal>
          <FullscreenModal.Container
            onClose={() => setOpen(false)}
            title="Fraser"
            titleIcon={BookOpenIcon}
          >
            <div className="pb-28">
              <div className="lg:max-w-[70%] mx-auto">
                <h3 className="text-supertakst-blue-900 text-xl font-medium text-center">
                  {partType?.name} {checkHeader && `– ${checkHeader}`}
                </h3>

                <div className="flex space-x-6 mt-12">
                  {hasUserPhrases && (
                    <div className="flex-1">
                      <h2 className="text-base text-supertakst-blue-900 font-medium mb-2">
                        Brukerfraser
                      </h2>
                      <PhrasesList phrases={fieldUserPhrases} onClick={doOnClick} type="PERSONAL" />
                    </div>
                  )}

                  {hasSystemPhrases && (
                    <div className="flex-1">
                      <h2 className="text-base text-supertakst-blue-900 font-medium mb-2">
                        Systemfraser
                      </h2>
                      <PhrasesList phrases={fieldSystemPhrases} onClick={doOnClick} type="SYSTEM" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </FullscreenModal.Container>
        </Portal>
      )}
    </>
  );
};

export default Phrases;
