import React from 'react';
import { useFormContext } from 'react-hook-form';
import ControlledButton from '@components/common/atoms/ControlledButton';
import useFieldState from '@components/hooks/useFieldState';
import FieldError from '@components/common/FieldError';

type Props = {
  onChange: (value: boolean | null) => void;
  onBlur: () => void;
  inputRef: any;
  value: boolean | null;
  id: string;
};

const BoolInput = ({ id, onChange, onBlur, inputRef, value }: Props) => {
  const { trigger } = useFormContext();
  const [error, touched] = useFieldState(id);

  const doOnClick = (ev: React.MouseEvent<HTMLButtonElement>, newValue) => {
    ev.preventDefault();
    if (value === newValue) {
      onChange(null);
    } else {
      onChange(newValue);
    }
    trigger();
  };

  return (
    <>
      <div
        className="flex flex-wrap -ml-3 -mb-3 child:ml-3 child:mb-3"
        ref={inputRef}
        data-test-type="checkboolean"
      >
        <ControlledButton
          onClick={(ev) => doOnClick(ev, true)}
          onBlur={onBlur}
          label="Ja"
          selected={value === true}
        />

        <ControlledButton
          onClick={(ev) => doOnClick(ev, false)}
          onBlur={onBlur}
          label="Nei"
          selected={value === false}
        />
      </div>
      {touched && error && <FieldError error={error} />}
    </>
  );
};

export default BoolInput;
