import { useEffect } from 'react';
import { DefaultValues, useForm, UseFormReturn } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Router, useRouter } from 'next/router';
import { Areal, GenerellInformasjon, TekniskVerdi } from '@supertakst/model-common';
import { BasePartForm } from '@components/Parts/BasePartForm';
import { useBeforeUnload } from 'react-use';
import { useAtom } from 'jotai';
import { availabilityHasChangedAtom } from '@store/atoms';

const usePartForm = <T extends BasePartForm | GenerellInformasjon | Areal | TekniskVerdi>(
  validation: Joi.AnySchema,
  defaultValues: DefaultValues<T>,
  partType: string,
  doOnSave: (values) => void
): [UseFormReturn<T, any>] => {
  const router = useRouter();
  const formMethods = useForm<T>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(validation),
    defaultValues: defaultValues,
  });

  const [availabilityHasChanged, setAvailabilityHasChanged] = useAtom(availabilityHasChangedAtom);

  const { isDirty } = formMethods.formState;
  useBeforeUnload(isDirty, 'Du har ulagrede endringer.');

  useEffect(() => {
    (async () => {
      await formMethods.trigger();
    })();
  }, [formMethods]);

  useEffect(() => {
    const handler = () => {
      const { isDirty } = formMethods.formState;
      if (isDirty || availabilityHasChanged) {
        const values = formMethods.getValues();
        setAvailabilityHasChanged(false);
        doOnSave({ ...values, partType });
      }
      return true;
    };
    Router.events.on('routeChangeStart', handler);
    return () => {
      Router.events.off('routeChangeStart', handler);
    };
  }, [doOnSave, formMethods, partType, router]);

  return [formMethods];
};

export default usePartForm;
