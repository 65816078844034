// Figure out if part has checks that have either value or values set. If so, it is modified.

import { Part, StandalonePartType } from '@supertakst/model-common';

const partIsModified = (part: Part) => {
  const commonKeys = ['id', 'partType', 'name', 'availability'];
  const checkKeys = Object.keys(part).filter((key) => !commonKeys.includes(key));
  return checkKeys.length !== 0;
};

const standalonePartIsModified = (part: StandalonePartType) => {
  return Object.keys(part).length > 0;
};

export const isModified = (part: Part | StandalonePartType) => {
  // Check if part is of type Part or StandalonePart
  if ('availability' in part) {
    return partIsModified(part);
  } else {
    return standalonePartIsModified(part);
  }
};
