import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import Header from '@components/common/Header';
import useFieldState from '@components/hooks/useFieldState';
import Asterix from '@components/common/Asterix';
import FieldError from '@components/common/FieldError';
import { twMerge } from 'tailwind-merge';

type Props = {
  id: string;
  type: string;
  format?: string;
  header?: string;
  className?: string;
} & React.HTMLProps<HTMLInputElement>;

const Input = ({ id, type, format, header, className, ...rest }: Props) => {
  const { control, register } = useFormContext();
  const [error, touched] = useFieldState(id);
  const isSimple = type !== 'radio' && type !== 'checkbox';

  const classes = twMerge(
    isSimple
      ? 'text-base text-supertakst-blue-900 bg-supertakst-gray-50 appearance-none border border-[#303030]/20 rounded w-full h-14 px-5 leading-tight focus:outline-none focus:shadow-outline'
      : 'mr-2 bg-supertakst-gray-50 border border-[#303030]/20 rounded h-6 w-6',
    (rest.disabled || rest.readOnly) && 'bg-supertakst-gray-900 text-neutral-dark-contrast/50'
  );

  const renderInput = () => {
    if (format === 'number') {
      return (
        <Controller
          name={id}
          control={control}
          render={({ field: { onBlur, onChange, name, value } }) => {
            return (
              <NumericFormat
                data-test-type="number-format-input"
                name={name}
                value={value}
                onBlur={onBlur}
                thousandSeparator=" "
                valueIsNumericString
                allowNegative={false}
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                className={classes}
              />
            );
          }}
        />
      );
    }
    return (
      <input
        data-test-type="input"
        type={type}
        {...register(id, {
          ...(type === 'number' && {
            setValueAs: (v) =>
              v === undefined || v === null || v.length === 0 ? null : parseInt(v),
          }),
        })}
        {...(type === 'number' && { onWheel: (e) => e.currentTarget.blur() })}
        className={classes}
        {...rest}
      />
    );
  };

  return (
    <div className={className}>
      {header ? (
        <Header as="label" className="text-color-supertakst-900 inline-block w-full">
          {isSimple ? (
            <>
              <span className="mb-2.5 inline-block">{header}</span> {error && <Asterix />}
              {renderInput()}
            </>
          ) : (
            <>
              {renderInput()}
              {header} {error && <Asterix />}
            </>
          )}
        </Header>
      ) : (
        renderInput()
      )}
      {touched && error && <FieldError error={error} />}
    </div>
  );
};

export default Input;
