import React from 'react';
import { useFormContext } from 'react-hook-form';
import ControlledButton from '@components/common/atoms/ControlledButton';
import { Alternative } from '../v1';
import useFieldState from '@components/hooks/useFieldState';
import FieldError from '@components/common/FieldError';

type Props = {
  onChange: (value: boolean | null | string) => void;
  onBlur: () => void;
  inputRef: any;
  value: string | null;
  id: string;
  alternatives: Alternative[];
  disableUncheck?: boolean;
};

const TextInput = ({
  id,
  onChange,
  onBlur,
  inputRef,
  value,
  alternatives,
  disableUncheck = false,
}: Props) => {
  const { trigger } = useFormContext();
  const [error, touched] = useFieldState(id);
  const doOnClick = (ev: React.MouseEvent<HTMLButtonElement>, newValue) => {
    ev.preventDefault();
    if (value === newValue) {
      if (disableUncheck) {
        return;
      }
      onChange(null);
      trigger();
    } else {
      onChange(newValue);
      trigger();
    }
  };

  return (
    <>
      <div
        className="flex flex-wrap -ml-3 -mb-3 child:ml-3 child:mb-3"
        ref={inputRef}
        data-test-type="checktext"
        data-check-id={id}
      >
        {alternatives.map(({ value: alternativeValue, label: alternativeLabel }) => {
          const combinedValue = `${alternativeValue}|${alternativeLabel}`;
          const alternativeKey = `${id}-${alternativeValue}|${alternativeLabel}`;
          return (
            <ControlledButton
              key={alternativeKey}
              onClick={(ev) => doOnClick(ev, combinedValue)}
              onBlur={onBlur}
              label={alternativeLabel}
              selected={combinedValue === value}
            />
          );
        })}
      </div>
      {touched && error && <FieldError error={error} />}
    </>
  );
};

export default TextInput;
