import { createStore, del, get, set } from 'idb-keyval';
import { Auth0Client } from '@auth0/auth0-spa-js';
import jwtDecode, { JwtPayload } from 'jwt-decode';

const isOnServer = () => typeof window === 'undefined';
let store;
const getStore = () => {
  if (store) return store;
  if (!isOnServer()) {
    store = createStore('auth0', 'cache');
    return store;
  }
};
export const IndexedDbCache = {
  get: async (key) => {
    try {
      if (isOnServer()) return null;
      return await get(key, getStore());
    } catch (err) {
      console.warn(`get failed: ${err.message}`);
      return null;
    }
  },

  set: async (key, value) => {
    if (isOnServer()) return;
    try {
      await set(key, value, getStore());
    } catch (err) {
      console.warn(`set failed: ${err.message}`);
    }
  },

  remove: async (key) => {
    if (isOnServer()) return;
    try {
      await del(key, getStore());
    } catch (err) {
      console.warn(`remove failed: ${err.message}`);
    }
  },
};

const auth0Client = new Auth0Client({
  domain: `${process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL}`,
  client_id: `${process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}`,
  audience: `${process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}`,
  cache: IndexedDbCache,
  useRefreshTokens: true,
});

export const getTokenSilently = async () => {
  return auth0Client.getTokenSilently();
};

export const getDecodedAccessToken = async (): Promise<JwtPayload | undefined> => {
  try {
    const token = await getTokenSilently();
    if (token) {
      return jwtDecode<JwtPayload>(token);
    }
  } catch (err) {
    console.warn(`Error parsing jwt token`, err);
  }
};
