import { useFormContext } from 'react-hook-form';

const useFieldState = (id) => {
  const {
    formState: { errors, touchedFields },
  } = useFormContext();

  // strip . and [] so we get an array of keys, e.g. id = "myfield.value" => ['myfield', 'value']
  const path = id.match(/([^[.\]])+/g);

  const error = path?.reduce((prevObj: object, key: string) => prevObj && prevObj[key], errors);
  const touched = path?.reduce(
    (prevObj: object, key: string) => prevObj && prevObj[key],
    touchedFields
  );

  return [error, touched];
};

export default useFieldState;
