import {
  Phrase,
  Tilstandsrapport,
  Tooltip,
  UserProfile,
  UserProfileUpdate,
} from '@supertakst/model-common';
import { clear, createStore, del, get, set, values } from 'idb-keyval';

export type TilstandsrapportWithDirty = Tilstandsrapport & { dirty?: boolean };

let tilstandsrapporter_db;
let user_phrases_db;
let system_phrases_db;
let tooltips_db;
let userprofile_db;

export const getTilstandsrapportDbName = async (userId: string) => {
  const onBehalfOf = await get('onBehalfOf');
  return onBehalfOf ? `tilstandsrapporter_${userId}_${onBehalfOf}` : `tilstandsrapporter_${userId}`;
};

export const deleteTilstandsrapportDb = async (userId?: string) => {
  if (!userId) return;
  const tilstandsrapporter_db_name = await getTilstandsrapportDbName(userId);
  indexedDB.deleteDatabase(tilstandsrapporter_db_name);
};

export const initStore = async (userId?: string) => {
  if (!userId) return;
  const tilstandsrapporter_db_name = await getTilstandsrapportDbName(userId);
  tilstandsrapporter_db = await createStore(tilstandsrapporter_db_name, 'tilstandsrapporter');
  user_phrases_db = await createStore(`user_phrases_${userId}`, 'user_phrases');
  system_phrases_db = await createStore(`system_phrases`, 'system_phrases');
  tooltips_db = await createStore(`tooltips`, 'tooltips');
  userprofile_db = await createStore(`user_profile_${userId}`, 'user_profile');
};

export const getOngoingTilstandsrapporter = async (): Promise<{
  [id: string]: TilstandsrapportWithDirty;
}> => {
  if (!tilstandsrapporter_db) {
    throw new Error('Tilstandsrapporter store not initialized');
  }
  const localReports = await values(tilstandsrapporter_db);
  return localReports.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});
};

export const getTilstandsrapport = async (
  id: number
): Promise<TilstandsrapportWithDirty | undefined> => {
  if (!tilstandsrapporter_db) {
    throw new Error('Tilstandsrapporter store not initialized');
  }
  return get(id, tilstandsrapporter_db);
};

export const setTilstandsrapport = async (tilstandsrapport: TilstandsrapportWithDirty) => {
  if (!tilstandsrapporter_db) {
    throw new Error('Tilstandsrapporter store not initialized');
  }
  await set(tilstandsrapport.id, tilstandsrapport, tilstandsrapporter_db);
};

export const deleteTilstandsrapport = async (id: number) => {
  if (!tilstandsrapporter_db) {
    throw new Error('Tilstandsrapporter store not initialized');
  }
  await del(id, tilstandsrapporter_db);
};

export const deleteAllTilstandsRapport = async () => {
  if (!tilstandsrapporter_db) {
    throw new Error('Tilstandsrapporter store not initialized');
  }
  await clear(tilstandsrapporter_db);
};

export const setUserPhrases = async (phrases): Promise<void> => {
  if (!user_phrases_db) {
    throw new Error('User phrases store not initialized');
  }
  await set('user_phrases', phrases, user_phrases_db);
};

export const getUserPhrases = async (): Promise<Phrase[]> => {
  if (!user_phrases_db) {
    throw new Error('User phrases store not initialized');
  }
  return (await get('user_phrases', user_phrases_db)) || [];
};

export const setSystemPhrases = async (phrases): Promise<void> => {
  if (!system_phrases_db) {
    throw new Error('System phrases store not initialized');
  }
  await set('system_phrases', phrases, system_phrases_db);
};

export const getSystemPhrases = async (): Promise<Phrase[]> => {
  if (!system_phrases_db) {
    throw new Error('System phrases store not initialized');
  }
  return (await get('system_phrases', system_phrases_db)) || [];
};

export const setTooltips = async (tooltips): Promise<void> => {
  if (!tooltips_db) {
    throw new Error('Tooltips store not initialized');
  }
  await set('tooltips', tooltips, tooltips_db);
};

export const getTooltips = async (): Promise<Tooltip[]> => {
  if (!tooltips_db) {
    throw new Error('Tooltips store not initialized');
  }
  return (await get('tooltips', tooltips_db)) || [];
};

export const setUser = async (
  user: UserProfile | UserProfileUpdate,
  overwriteUpdatedAt: boolean = false
) => {
  if (!userprofile_db) {
    throw new Error('Userprofile store not initialized');
  }
  const updatedUser = overwriteUpdatedAt ? { ...user, updatedAt: new Date().toISOString() } : user;
  await set('user_profile', updatedUser, userprofile_db);
};

export const getUser = async (): Promise<UserProfile | undefined> => {
  if (!userprofile_db) {
    throw new Error('User store not initialized');
  }
  return await get('user_profile', userprofile_db);
};
