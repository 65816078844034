import axios from 'axios';
import { onlineManager } from '@tanstack/react-query';
import { UserProfile, UserProfileUpdate } from '@supertakst/model-common';
import { getUser, setUser } from '@utils/localDb';
import * as auth0Utils from '@utils/auth0';

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  timeout: 20000,
});
client.interceptors.request.use(async (reqConfig) => {
  const accessToken = await auth0Utils.getTokenSilently();
  return {
    ...reqConfig,
    headers: { ...reqConfig.headers, Authorization: `Bearer ${accessToken}` },
  };
});

export const fetchUser = async (): Promise<UserProfile | undefined> => {
  let localUser = await getUser();
  if (!onlineManager.isOnline()) {
    return localUser;
  }

  try {
    const { data } = await client.get<UserProfile>('/users');
    if (localUser?.updatedAt && localUser?.updatedAt > data.updatedAt) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, createdAt, updatedAt, uid, ...updateUser } = localUser;
      const updatedUser = await registerOrUpdateUser(updateUser);
      setUser(updatedUser);
      return updatedUser;
    }
    await setUser(data);
    return data;
  } catch (err) {
    if (err.response.status === 404) {
      return;
    }
    throw err;
  }
};

export const fetchUserByUid = async (uid: string): Promise<UserProfile | undefined> => {
  const { data } = await client.get<UserProfile>(`/users/${uid}`);
  return data;
};

export const registerOrUpdateUser = async (userData: UserProfileUpdate): Promise<UserProfile> => {
  if (!onlineManager.isOnline()) {
    await setUser(userData, true);
    return (await getUser()) ?? Promise.reject('Kunne ikke registere bruker');
  }
  const { data } = await client.post<UserProfile>('/users', userData);
  await setUser(data);
  return data;
};
