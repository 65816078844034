export const tilstandsgrader = {
  '-1': {
    label: 'TG-IU',
    value: -1,
  },
  '0': {
    label: 'TG-0',
    value: 0,
  },
  '1': {
    label: 'TG-1',
    value: 1,
  },
  '2': {
    label: 'TG-2',
    value: 2,
  },
  '3': {
    label: 'TG-3',
    value: 3,
  },
};
