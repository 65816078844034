import axios from 'axios';
import { onlineManager } from '@tanstack/react-query';
import { getTooltips, setTooltips } from '@utils/localDb';
import { Tooltip } from '@supertakst/model-common';
import * as auth0Utils from '@utils/auth0';

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  timeout: 20000,
});

export const fetchAllTooltips = async (): Promise<Tooltip[]> => {
  const accessToken = await auth0Utils.getTokenSilently();
  const reqConfig = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  if (onlineManager.isOnline()) {
    const { data } = await client.get(`/tooltips`, reqConfig);
    await setTooltips(data);
    return data;
  } else {
    return await getTooltips();
  }
};
