import {
  Ambita,
  BoligType,
  getModelVersion,
  LATEST_MODEL_VERSION,
  ModelTextsVersions,
  modelUtils,
  PartAvailability,
  Tilstandsrapport,
} from '@supertakst/model-common';
import { streetAddress } from '@utils/ambita';
import Joi, { AnySchema } from 'joi';
import { NotAvailableSchema as NotAvailableSchemaV1 } from '@components/Parts/NotAvailable/v1';
import { NotAvailableSchema as NotAvailableSchemaV5 } from '@components/Parts/NotAvailable/v5';
import { NotAvailableSchema as NotAvailableSchemaV6 } from '@components/Parts/NotAvailable/v6';

export const availability_available = {
  value: 'available|Tilgjengelig',
};

export const getCheckHeader = (modelVersion: number, partType: string, checkId: string): string => {
  const result = ModelTextsVersions[`v${modelVersion}`][`${partType}`]?.checks[`${checkId}`];
  if (!result) {
    console.warn(`Unable to find model texts for v${modelVersion}.${partType}.${checkId}`);
    return 'Ukjent tittel!';
  }
  return result.header;
};

export type NewTilstandsrapportData = {
  type: BoligType;
  ambita?: {
    address: Ambita.Address;
    share?: Ambita.Share & { owners: Array<{ navn: string }> };
    cadastre?: Ambita.Cadastre & { owners: Array<{ navn: string }> };
  };
  manual?: {
    knr: number;
    gnr: number;
    bnr: number;
    fnr?: number;
    snr?: number;
    anr?: number;
    lnr?: number;
    adresse: string;
    postnr: string;
    poststed: string;
  };
  befaringsDato?: { value?: Date };
};

export const newTilstandsrapport = (data: NewTilstandsrapportData) => {
  const ident = data.ambita?.share?.landLot?.ident || data.ambita?.cadastre?.ident;
  const street = data.manual ? data.manual.adresse : streetAddress(data.ambita?.address);
  const postalCode = data.manual ? data.manual.postnr : data.ambita?.address?.street?.postalCode;
  const postalPlace = data.manual
    ? data.manual.poststed
    : data.ambita?.address?.street?.postalPlace;
  const knr = data.manual ? data.manual.knr : ident?.municipalityNumber;
  const gnr = data.manual ? data.manual.gnr : ident?.cadastralUnitNumber;
  const bnr = data.manual ? data.manual.bnr : ident?.unitNumber;
  const fnr = data.manual ? data.manual.fnr : ident?.leaseholdUnitNumber;
  const snr = data.manual ? data.manual.snr : ident?.sectionNumber;
  const anr = data.manual ? data.manual.anr : data.ambita?.share?.ident.shareNumber;
  const lnr = data.manual?.lnr;
  const befaringsDato = data.befaringsDato?.value
    ? { ...data.befaringsDato, touched: true }
    : undefined;

  return {
    modelVersion: LATEST_MODEL_VERSION,
    id: -new Date(),
    uid: 'UNKNOWN',
    createdAt: new Date(),
    updatedAt: new Date(),
    generellInformasjon: {
      befaringsDato,
      boligType: data.type,
      hjemmelshavere: { values: data.ambita?.share?.owners || data.ambita?.cadastre?.owners },
      image: {},
      matrikkel: {
        touched: true,
        adresse: street,
        postnr: modelUtils.formatPostalCode(postalCode ?? ''),
        poststed: postalPlace,
        knr,
        gnr,
        bnr,
        fnr,
        snr,
        anr,
        lnr,
      },
    },
    tekniskVerdi: {},
    areal: {},
    updateHistory: [],
    notater: [],
    parts: modelUtils.generatePartsFromBoligType(data.type, LATEST_MODEL_VERSION),
  } as Tilstandsrapport;
};

/* @TODO: burde vi bruke modell-versjon fra tilstandsrapport og ikke LATEST_MODEL_VERSION her? */
export const canDeletePart = (partTypeToBeDeleted: string, tilstandsrapport: Tilstandsrapport) => {
  const requiredParts =
    (tilstandsrapport.generellInformasjon.boligType === BoligType.BOLIG
      ? [
          ...getModelVersion(LATEST_MODEL_VERSION).CommonParts,
          ...getModelVersion(LATEST_MODEL_VERSION).HouseParts,
        ]
      : getModelVersion(LATEST_MODEL_VERSION).CommonParts
    ).map((part) => part.id) || [];

  if (requiredParts.includes(partTypeToBeDeleted)) {
    return (
      tilstandsrapport.parts.filter((part) => part.partType === partTypeToBeDeleted).length > 1
    );
  }
  return true;
};

export const isTmpId = (tilstandsrapportId: string | string[] | number): boolean => {
  return parseTilstandsrapportIdFromQuery(tilstandsrapportId) < 0;
};

export const hasTmpId = (tilstandsrapport: Tilstandsrapport) => {
  return isTmpId(tilstandsrapport.id);
};

export const parseTilstandsrapportIdFromQuery = (
  tilstandsrapportId: string | string[] | number
): number => {
  if (Array.isArray(tilstandsrapportId)) {
    // if we get more than 1 entry, we're doing something wrong. This should never happen.
    return Number(tilstandsrapportId[0]);
  }
  return Number(tilstandsrapportId);
};

export const withAvailabilitySchema = ({
  schema,
  concatAvailability = true,
  version = 1,
}: {
  schema: AnySchema;
  concatAvailability?: boolean;
  version?: number;
}) => {
  // Note! This is a "hack" to make sure we can use the same schema for both v1 and vX.
  // Every schema up to v5 has the same availability schema, so we can use the same schema for all of them.
  // From v5 and up, we need to use the new availability schema.
  const NotAvailableSchema = {
    v1: NotAvailableSchemaV1,
    v2: NotAvailableSchemaV1,
    v3: NotAvailableSchemaV1,
    v4: NotAvailableSchemaV1,
    v5: NotAvailableSchemaV5,
    v6: NotAvailableSchemaV6,
    v7: NotAvailableSchemaV6,
    v8: NotAvailableSchemaV6,
  };
  // @FIXME: not good to have another v dependency update here? Or update readme with info?

  return Joi.when(
    Joi.object({
      availability: Joi.object({
        value: `${PartAvailability.Available.id}|${PartAvailability.Available.name}`,
      }).unknown(),
    }).unknown(),
    {
      then: concatAvailability
        ? schema.concat(
            Joi.object({
              availability: Joi.object({
                value: Joi.string().required(),
                touched: Joi.boolean().presence('optional'),
              }),
            })
          )
        : schema,
      otherwise: NotAvailableSchema[`v${version}`],
    }
  );
};
