import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckText from '@components/common/CheckText/v1';
import { getCheckHeader } from '@utils/modelUtils';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import { CheckSingle, PartAvailability, PartTypes } from '@supertakst/model-common';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import { PartImages } from '@components/common/Images';

interface Props extends BasePartComponentProps<NotAvailableFormData> {
  partType: string;
}

export const NotAvailableForm = ({ defaultValues, doOnSave, partType }: Props) => {
  const [formMethods] = usePartForm<NotAvailableFormData>(
    NotAvailableSchema,
    defaultValues,
    partType,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const availability = formMethods.watch('availability.value');
  useEffect(() => {
    if (availability && availability === 'not_relevant|Ikke relevant') {
      setValue('totalvurdering.value', null, { shouldValidate: true });
      setValue('totalvurdering.tg', null, { shouldValidate: true });
      setValue('utbedringskostnader.value', null, { shouldValidate: true });
      setValue('utbedringskostnader.comment', null, { shouldValidate: true });
      setValue('anbefalteTiltak.value', null, { shouldValidate: true });
      setValue('anbefalteTiltak.comment', null, { shouldValidate: true });
    }
  }, [setValue, availability]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null, { shouldValidate: true });
      setValue('utbedringskostnader.comment', null, { shouldValidate: true });
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null, { shouldValidate: true });
      setValue('anbefalteTiltak.comment', null, { shouldValidate: true });
    }
  }, [totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null, { shouldValidate: true });
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes[partType].name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        {availability !== 'not_relevant|Ikke relevant' && (
          <>
            <SingleTextarea
              id="totalvurdering"
              header={getCheckHeader(1, 'common', 'totalvurdering')}
              hasTg
            />

            {totalvurderingTg === 3 && (
              <CheckText
                id="utbedringskostnader"
                header={getCheckHeader(1, 'common', 'utbedringskostnader')}
                alternatives={[
                  { value: '0', label: 'Ingen umiddelbar kostnad' },
                  { value: '10000', label: 'Under 10 000' },
                  { value: '10000-50000', label: '10 000 - 50 000' },
                  { value: '50000-150000', label: '50 000 - 150 000' },
                  { value: '150000-300000', label: '150 000 - 300 000' },
                  { value: '300000', label: 'Over 300 000' },
                ]}
              />
            )}

            {totalvurderingTg !== undefined &&
              totalvurderingTg !== null &&
              totalvurderingTg !== 0 &&
              totalvurderingTg !== 1 && (
                <CheckBoolean
                  id="anbefalteTiltak"
                  header={getCheckHeader(1, 'common', 'anbefalteTiltak')}
                  hideComment={getValues('anbefalteTiltak.value') === false}
                />
              )}
          </>
        )}
      </form>
      {availability !== 'not_relevant|Ikke relevant' && (
        <PartImages
          existingImages={getValues('images')}
          setImageValues={setValue}
          heading={PartTypes[partType].name}
        />
      )}
    </FormProvider>
  );
};

export const NotAvailableSchema = Joi.object({
  availability: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('/availability.value', {
      not: `${PartAvailability.NotRelevant.id}|${PartAvailability.NotRelevant.name}`,
      then: Joi.when('..tg', {
        is: 3,
        then: Joi.string().required(),
      })
        .concat(
          Joi.when('..tg', {
            is: 2,
            then: Joi.string().required(),
          })
        )
        .concat(
          Joi.when('..tg', {
            is: -1,
            then: Joi.string().required(),
            otherwise: Joi.any(),
          })
        ),
    }),
    tg: Joi.when('/availability.value', {
      not: `${PartAvailability.NotRelevant.id}|${PartAvailability.NotRelevant.name}`,
      then: Joi.number().integer().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when('/totalvurdering.tg', { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown(true)
  .messages({ '*': 'Dette feltet er påkrevet' });

interface NotAvailableFormData extends BasePartForm {
  totalvurdering?: CheckSingle<string>;
  utbedringskostnader?: CheckSingle<string>;
  anbefalteTiltak?: CheckSingle<boolean>;
}

export default NotAvailableForm;
