import { ReactNode } from 'react';
import { useCheckCardIdContext } from '@components/common/CheckCard/CheckCardItem';
import useFieldState from '@components/hooks/useFieldState';
import { twMerge } from 'tailwind-merge';
import { isModified } from '@utils/isModified';
import { useDefaultValuesContext } from '@components/common/DefaultValuesContext';

type Props = {
  children: ReactNode;
};

const CheckCardHeader = ({ children }: Props) => {
  const { id } = useCheckCardIdContext();
  const { defaultValues } = useDefaultValuesContext();
  const edited = isModified(defaultValues);
  const [errors] = useFieldState(id);

  const classes = twMerge(
    'flex items-center p-4',
    !errors ? 'bg-valid text-valid-dark-contrast' : edited ? 'bg-error-indication' : ''
  );

  return (
    <div className={classes}>
      <div className="flex items-center mr-2">{children}</div>
    </div>
  );
};

export default CheckCardHeader;
