import { UserProfile, UserProfileUpdate } from '@supertakst/model-common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchUser, fetchUserByUid, registerOrUpdateUser } from '@api/users';
import LogRocket from 'logrocket';

export const userProfileQueryKeys = {
  userProfile: () => ['userProfile'],
  userProfileByUid: (uid) => ['userProfile', uid],
};

export function useUserByUid(uid, options = {}) {
  return useQuery<UserProfile | null>(
    userProfileQueryKeys.userProfileByUid(uid),
    async () => {
      return (await fetchUserByUid(uid)) ?? null;
    },
    options
  );
}

export function useUser(options = {}) {
  return useQuery<UserProfile | null>(
    userProfileQueryKeys.userProfile(),
    async () => {
      return (await fetchUser()) ?? null;
    },
    options
  );
}

export function useMutateUser(onSuccessCb?, onErrorCb?) {
  const queryClient = useQueryClient();
  return useMutation((data: UserProfileUpdate) => registerOrUpdateUser(data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: userProfileQueryKeys.userProfile() });
      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (err: Error) => {
      if (onErrorCb) {
        onErrorCb();
      }
      LogRocket.captureException(err, {
        tags: {
          action: 'useMutateUser',
        },
      });
    },
  });
}
