import { useEffect } from 'react';

export const useTotalvurderingTgEffects = ({
  totalvurderingTg,
  setValue,
  utbedringskostnaderName = 'utbedringskostnader',
  anbefalteTiltakName = 'anbefalteTiltak',
}: {
  totalvurderingTg: number | null | undefined;
  setValue: any;
  utbedringskostnaderName?: string;
  anbefalteTiltakName?: string;
}) => {
  useEffect(() => {
    if (totalvurderingTg !== null && totalvurderingTg !== undefined) {
      if (totalvurderingTg !== 3) {
        setValue(`${utbedringskostnaderName}.value`, null, { shouldValidate: true });
      }
      if ([0, 1].includes(totalvurderingTg)) {
        setValue(`${anbefalteTiltakName}.value`, null, { shouldValidate: true });
      }
    }
  }, [totalvurderingTg, setValue]);
};
