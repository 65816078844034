import React from 'react';
import { useFormContext } from 'react-hook-form';
import Textarea from '@components/common/atoms/Textarea';
import TGSelector from '@components/common/TGSelector';
import CheckHeader from '@components/common/CheckHeader';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from '@components/common/CheckCard';
import Phrases from '@components/common/Phrases/v1';
import Tooltip from '@components/common/Tooltip';

type Props = {
  id: string;
  header: string;
  hasTg?: boolean;
  tgReadonly?: boolean;
  tilstandsgrader?: Array<-1 | 0 | 1 | 2 | 3>;
};

const SingleTextarea = ({
  id,
  header,
  hasTg = false,
  tgReadonly = false,
  tilstandsgrader = [-1, 0, 1, 2, 3],
}: Props) => {
  const valueName = `${id}.value`;
  const tgName = `${id}.tg`;
  const { getValues } = useFormContext();

  const hideComment = hasTg ? typeof getValues(tgName) !== 'number' : false;

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        {hasTg && (
          <TGSelector id={tgName} tilstandsgrader={tilstandsgrader} readonly={tgReadonly} />
        )}
        {(!hideComment || hasTg) && (
          <>
            <Textarea className="mt-6" id={valueName} />
            <Phrases id={id} textId={valueName} tgId={tgName} hasTg={hasTg} checkHeader={header} />
          </>
        )}
      </CheckCardContent>
    </CheckCardItem>
  );
};

export default SingleTextarea;
