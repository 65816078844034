import { Cloudinary } from '@cloudinary/url-gen';
import { fit, thumbnail } from '@cloudinary/url-gen/actions/resize';
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity';
import { FocusOn } from '@cloudinary/url-gen/qualifiers/focusOn';

const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_ACCOUNT,
  },
});

export const getImage = (id: string, type?: string | undefined) => {
  const imageBuilder = cld.image(id);
  if (type === 'thumb')
    return cld.image(id).resize(fit().width(640).height(480)).format('auto').toURL();
  if (type === 'face')
    return imageBuilder
      .resize(thumbnail().width(400).height(400).zoom(0.7).gravity(focusOn(FocusOn.face())))
      .format('auto')
      .toURL();
  if (type === 'logo') return imageBuilder.resize(fit().width(400)).format('auto').toURL();
  return imageBuilder.resize(fit().width(1024).height(768)).format('auto').toURL();
};
